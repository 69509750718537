import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import config from './../../config'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import { Tabs, Tab, Typography, Divider, colors, Snackbar, SnackbarContent } from '@material-ui/core';
import {setInterceptors} from './../../services/axios'
import useRouter from 'utils/useRouter';
import {getCookie} from './../../services/cookies'

import { Page } from 'components';
import { Header, Summary, Invoices, Logs } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  errorMessage:{
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
  },
  iconError:{
	marginRight: '0.5em',
  }
}));

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

const BookingsDetail = (props) => {
  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const router = useRouter();
  setInterceptors(router)
  const [bookings, setBookings] = useState();
  const [disabledDates, setDisabledDates] = useState()
  const [editDate, setEditDate] = useState()

  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
	  'message': '',
	  'color': ''
  })

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'summary', label: 'Resumen' },
    /*{ value: 'invoices', label: 'Invoices' },
    { value: 'logs', label: 'Logs' }*/
  ];

  const fetchCustomers = () => {
    axios.get(`${config.server}/getIndividualBookings/${id}`,{
        headers: {
            'Authorization': getCookie('__tw__')
        },
    }).then(response => {
        setBookings(response.data)
        getCalendarBookings(response.data)
    }).catch(e =>{

    });
  };

  const getCalendarBookings = async(office) => {
      try {
          const response = await fetch(`${config.server}/getBookingsOffice?office_id=${office.bookings.office_id}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json'
              },
          })

          const fetchData = await response.json()
          let disabledDatesArr = []
          setDisabledDates(disabledDatesArr)
          fetchData.map((el,idx)=>{
              if(el.id == id){
                  setEditDate([...getDates(new Date(el.from_date.replace(/-/g, ',')), new Date(el.to_date.replace(/-/g, ',')))])
              }else{
                  disabledDatesArr = [...disabledDatesArr, ...getDates(new Date(el.from_date.replace(/-/g, ',')), new Date(el.to_date.replace(/-/g, ',')))]
                  //if(idx == fetchData.length-1){
                  setDisabledDates(disabledDatesArr)
                  //}
              }
          })
      } catch (e) {
          console.log(e)
      }
  }
  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

  useEffect(() => {
	fetchCustomers();
  },[]);

  if (!tab) {
    return <Redirect to={`/reserva/${id}/summary`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
	  console.log("404 error")
    return <Redirect to="/errors/error-404" />;
  }

  return (<>
      {
          typeof bookings !== 'undefined' ? (
              <Page
                className={classes.root}
                title="Detalles de Reserva"
              >
                <Header bookings={bookings} />
                <Tabs
                  className={classes.tabs}
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  value={tab}
                  variant="scrollable"
                >
                  {tabs.map(tab => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
                <Divider className={classes.divider} />
                <div className={classes.content}>
                  {tab === 'summary' && typeof editDate != 'undefined' && <Summary editDate={editDate} getCalendarBookings={getCalendarBookings} disabledDates={disabledDates} history={history} fetchCustomers={fetchCustomers} bookings={bookings} setErrorMessage={setErrorMessage} setOpenSnack={setOpenSnack}/>}
                  {/*tab === 'invoices' && <Invoices bookings={bookings}/>*/}
                  {/*tab === 'logs' && <Logs bookings={bookings} />*/}
                </div>
				<SnackBarWrapper message={errorMessage.message}/>
              </Page>
          ):(
              <p>Cargando...</p>
          )
      }
  </>);
};

export default BookingsDetail;
