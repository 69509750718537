import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import config from './../../../../config'
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import {getCookie} from './../../../../services/cookies'

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = props => {
  const { handleCheckPublish, activatePublish, setOpenSnack, setErrorMessage, fetchCustomers, className, handleEditOpen, ...rest } = props;
  const classes = useStyles();

  const handlePublishChanges = async() => {
	  try{
		  const response = await axios({
			  method: 'POST',
			  headers: {
				  'Authorization': getCookie('__tw__')
			  },
			  url: `${config.server}/upload_changes`
		  });
		  if(response.status == 200){
			  handleCheckPublish()
			  fetchCustomers(1,10);
			  setErrorMessage({'message':'Los cambios se estan publicando, se paciente.','color':'#43a047'})
			  setOpenSnack(true)
		  }
	  }catch(e){

	  }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Gestor
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            Sedes
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            onClick={handleEditOpen}
            variant="contained"
            style={{marginLeft:10, 
            marginTop: 10}}
          >
            Agregar sede
          </Button>
		  <Button
			/* disabled={!activatePublish} */
            color="green"
      style={{marginLeft:10,
              marginTop: 10}}
              component={RouterLink}
              to={`/publicaciones`}
            /* onClick={handlePublishChanges} */
            variant="contained"
          >
            Publicar Cambios
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
