import React from 'react';
import { Router , Redirect, BrowserRouter, Route, Switch} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import './lottie.css'
import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';

Moment.globalLocale = 'es';
const history = createBrowserHistory();
const store = configureStore();

const App = () => {

	const RouteWithSubRoutes = (route) => {
		return (
			<Route path={route.path} render={(props) => (
		  	    <route.component {...props} routes={route.routes}/>
	  	  	)}/>
		)
	}

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
			<Switch>
              <Route path={'/'} render={()=><Redirect to="/auth/login"/>} exact />
              {
                  routes.map((route,idx)=>(
                      <RouteWithSubRoutes key={idx} {...route} />
	              ))
              }
		    </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
