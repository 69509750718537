import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import config from './../../../../config'
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import axios from 'utils/axios';
import { CustomerInfo, Invoices, SendEmails, OtherActions } from './components';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Summary = props => {
  const { history, className, setErrorMessage, setOpenSnack, users, fetchCustomers, ...rest } = props;
  const classes = useStyles();

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
      >
        <CustomerInfo fetchCustomers={fetchCustomers} users={users} setErrorMessage={setErrorMessage} setOpenSnack={setOpenSnack}/>
      </Grid>
      {/*<Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <Invoices fetchCustomers={fetchCustomers} users={users} setErrorMessage={setErrorMessage} setOpenSnack={setOpenSnack}/>
      </Grid>*/}
      {/*<Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <SendEmails fetchCustomers={fetchCustomers} users={users} />
      </Grid>*/}
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <OtherActions history={history} users={users} setErrorMessage={setErrorMessage} setOpenSnack={setOpenSnack}/>
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {
  className: PropTypes.string
};

export default Summary;
