import * as actionTypes from 'actions';

const initialState = {
  coordinates: {
      add: 'Cra 53 #72-136',
      lat: '10.9994607',
      lng: '-74.8051516'
  },
};

const mapReducer = (state=initialState,action)=>{
	switch (action.type) {
		case "map/MODIFY_MAP_MARKER":
			state.coordinates = action.data
			break
	}
    return state;
}

export default mapReducer;
