import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import config from '../../../../../../../../config'
import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  Button,
  colors
} from '@material-ui/core';
import {getCookie} from './../../../../../../../../services/cookies'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  controlLabel:{
    width: '30%',
    marginBottom: '1em'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  [theme.breakpoints.down('sm')]: {
    controlLabel:{
      width: '100%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    controlLabel:{
      justifyContent: 'center'
    }
  }
}));

const SpaceEdit = props => {
  const { open, onClose, setErrorMessage, setOpenSnack, headQuarterData, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    ...headQuarterData
  });

  const [checkeds,setCheckeds] = useState([])

  const [typeOffices, setTypeOffices] = useState()

  const onSave = async() => {
	  const tempCheckeds = [...checkeds]
      tempCheckeds.map(async(el,idx)=>{
          if(el.state){
              try{
                  const response = await axios({
                      method: 'POST',
                      headers: {
                          'Authorization': getCookie('__tw__')
                      },
                      url: `${config.server}/addOfficeTypeHeadQuarter`,
                      data: {
                          officeTypeId: el.id,
                          headQuarterId: headQuarterData.id
                      }
                  });
                  if (response.status == 200){
                      onClose()
                      fetchCustomers()
					  //getTypeOffices()
                  }
              }catch(e){
                  console.log(e)
              }
          }else{
              try{
                  const response = await axios({
                      method: 'POST',
                      url: `${config.server}/destroyOfficeTypeHeadQuarter`,
                      headers: {
                          'Authorization': getCookie('__tw__')
                      },
                      data: {
                          officeTypeId: el.id,
                          headQuarterId: headQuarterData.id
                      }
                  });
                  if (response.data.status == 200){
                      onClose()
                      fetchCustomers()
                      //getTypeOffices()
                  }else if(response.data.state == "TYPEOFFICE_HEADQUARTERS_EXISTS"){
					  let temporal = [...checkeds]
					  temporal[idx].state = !temporal[idx].state
					  setCheckeds([...temporal]);
					  setErrorMessage({'message':'Algunos tipos de espacios no pudieron ser eliminados porque tiene espacios asignados.','color':'#d32f2f'})
					  setOpenSnack(true)
				  }
              }catch(e){
                  console.log(e)
              }
          }
      })

  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const getTypeOffices = async() => {
      try{
          const response = await axios({
              method: 'GET',
              url: `${config.server}/office_types`
          });
          if (response.status == 200){
              setTypeOffices(response.data)
			  let tempAux = []
              response.data.map((el,idx)=>{
                  const flg = headQuarterData.office_type.find((ofty)=>ofty.id===el.id)
                  if(flg){
					  let tempCheckedsTrue;
					  if (idx == 0){
						  tempCheckedsTrue = []
					  }else{
						  tempCheckedsTrue = tempAux;
					  }
                      tempCheckedsTrue.push({id:el.id,state:true})
                      setCheckeds(tempCheckedsTrue)
					  tempAux = [...tempCheckedsTrue]
                  }else{
					  let tempCheckedsFalse;
					  if (idx == 0){
						  tempCheckedsFalse = []
					  }else{
						  tempCheckedsFalse = tempAux;
					  }
                      tempCheckedsFalse.push({id:el.id,state:false})
                      setCheckeds(tempCheckedsFalse)
					  tempAux = [...tempCheckedsFalse]
                  }
              })
          }
      }catch(e){
          console.log(e)
      }
  }

  const handleChecked = (idx) => {
      const tempCheckeds = [...checkeds]
      tempCheckeds[idx].state = !tempCheckeds[idx].state
      setCheckeds([...tempCheckeds])
  }

  useEffect(()=>{
      getTypeOffices()
  },[])


  if (!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Editar tipos de espacios
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
                style={{textAlign:'center'}}
              >
              {
				  checkeds.length != 0 && typeof checkeds !== 'undefined' ? (
					  typeOffices.map((el,idx)=> {
						  return(
							  <FormControlLabel
								  className={classes.controlLabel}
								  key={idx}
								  control={
									  <Checkbox
										  color="primary"
										  checked={checkeds[idx].state}
										  onChange={() => handleChecked(idx)}
										  />
								  }
								  label={el.name}
								  />
						  )
					  })
				  ):(
					  <p>Cargando...</p>
				  )
              }
              </Grid>
              <Grid item/>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

SpaceEdit.displayName = 'SpaceEdit';

SpaceEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SpaceEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default SpaceEdit;
