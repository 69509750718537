import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import config from './../../../../config'
import axios from 'axios';
import { Page, SearchBar } from 'components';
import { Header, Results, CustomerEdit } from './components';
import { SnackbarContent, Snackbar, Typography } from '@material-ui/core';
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import {getCookie} from './../../../../services/cookies'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  errorMessage:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconError:{
    marginRight: '0.5em',
  }
}));

const Spaces = (props) => {
  const classes = useStyles();
  const { actualHeadQuarterData } = props;

  const [spaces, setSpaces] = useState();
  const [willPaginate, setWillPaginate] = useState();
  const [activatePublish, setActivatePublish] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [officeTypes, setOfficesTypes] = useState()
  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    'message': '',
    'color': ''
  })

  const handleCheckPublish = async() => {
	  try{
		  const response = await axios({
			  method: 'GET',
			  headers: {
				  'Authorization': getCookie('__tw__')
			  },
			  url: `${config.server}/checkPublished`
		  });
		  setActivatePublish(response.data)
	  }catch(e){

	  }
  }

  const fetchCustomers = (page, perPage) => {
    axios.get(`${config.server}/getOfficeByHeadQuarterPaginate?page=${page}&per_page=${perPage}&headQuarterId=${actualHeadQuarterData.id}`).then(response => {
        setSpaces(response.data.offices);
        setWillPaginate({page:response.data.page-1, pages: response.data.pages, totalRows: response.data.total_rows})
    });
  };

  const getOfficeType = async() => {
      try{
          const response = await axios({
              method: 'GET',
              url: `${config.server}/getOfficesTypesByHeadQuarters?headQuarterId=${actualHeadQuarterData.id}`
          });
          if (response.data.status == 200){
              setOfficesTypes(response.data.data)
          }
      }catch(e){
          console.log(e)
      }
  }

  useEffect(() => {
    let mounted = true;

    fetchCustomers(1,10);
    getOfficeType();
	handleCheckPublish()

    return () => {
      mounted = false;
    };
  }, []);

  const handleFilter = () => {};
  const handleSearch = () => {};

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

  return (
    <Page
      className={classes.root}
      title="Espacios"
    >
      <Header
	   	  handleCheckPublish={handleCheckPublish}
		  handleEditOpen={handleEditOpen}
		  fetchCustomers={fetchCustomers}
          setOpenSnack={setOpenSnack}
	   	  activatePublish={activatePublish}
          setErrorMessage={setErrorMessage}
	  />
      {/*<SearchBar
        onFilter={handleFilter}
        onSearch={handleSearch}
      />*/}
  {spaces && willPaginate && officeTypes && (
        <Results
          className={classes.results}
          spaces={spaces}
          actualHeadQuarterData={actualHeadQuarterData}
          fetchCustomers={fetchCustomers}
          willPaginate={willPaginate}
        />
      )}
      <CustomerEdit
        onClose={handleEditClose}
        officeTypes={officeTypes}
		handleCheckPublish={handleCheckPublish}
        open={openEdit}
        actualHeadQuarterData={actualHeadQuarterData}
        fetchCustomers={fetchCustomers}
        setOpenSnack={setOpenSnack}
        setErrorMessage={setErrorMessage}
      />
    <SnackBarWrapper message={errorMessage.message}/>
    </Page>
  );
};

export default Spaces;
