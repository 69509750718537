/*import { createStore, combineReducers, applyMiddleware } from 'redux';



const map = (state=[],action)=>{
	switch (action.type) {
		case "map/MODIFY_MAP_MARKER":
			state.coordinates = action.data
			break
	}
    return state;
}

const finalStore = createStore(combineReducers({map}));

export default finalStore*/

import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import publishReducer from './publishReducer';
import mapReducer from './mapReducer';


const rootReducer = combineReducers({
  session: sessionReducer,
  publish: publishReducer,
  map: mapReducer
});

export default rootReducer;
