import React, { useState } from 'react';
import clsx from 'clsx';
import config from '../../../../config'
import Geocode from "react-geocode";
import axios from 'axios'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Script from 'react-load-script';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Switch,
  Button,
  colors
} from '@material-ui/core';
import MapViewEdit from '../../../../components/MapViewEdit'
import { useSelector, useDispatch } from 'react-redux'
import Lottie from 'lottie-react-web'
import animation from './../../../../animations/loading-cowork2.json'
import {getCookie} from '../../../../services/cookies'
import useRouter from 'utils/useRouter';

const apiKeyMaps = 'AIzaSyAM_Vnd5sRbUqGLTLhxvwJ2wAffX5K7kVo'

Geocode.setApiKey(apiKeyMaps);

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  autocompleteField: {
    /*     fontFamily: 'Roboto', */
        width: "314px",
        height: "56px"
      },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  containerMapText:{
    padding: '1em'
  },
  containerMapTextItem:{
    position: 'relative',
    width: '100%',
    height: '200px'
  }
}));

const CustomerEdit = props => {
  const { handleCheckPublish, setErrorMessage, setOpenSnack, open, onClose, headQuarterData, fetchCustomers, className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formState, setFormState] = useState({
      name: ''
  });

  const [loadingRequest, setLoadingRequest] = useState(false)

  const [coordinates, setCoordinates] = useState({
    'lat': '10.9994565',
    'lng': '-74.8044738'
})

  const actualCoordinates = useSelector(state => state.map.coordinates)
  if (!open) {
    return null;
  }

  const onSave = async() => {
      if ((/^\s*$/.test(formState.name))){
          setErrorMessage({'message':'No puedes dejar valores en blanco.','color':'#d32f2f'})
          setOpenSnack(true)
      }else{
          setLoadingRequest(true)
          try{
              const response = await axios({
                  method: 'POST',
                  headers: {
                      'Authorization': getCookie('__tw__')
                  },
                  url: `${config.server}/head_quarters`,
                  data: {
                      name: formState.name,
                      address: {add: actualCoordinates.add, lat: actualCoordinates.lat, lon: actualCoordinates.lng},
                      //feature_image_uid: 'sede_placeholder.png'
                  }
              });

              if (response.data.state == "SUCCESS"){
                  const responseOfficeType = await axios({
                      method: 'GET',
                      headers: {
                          'Authorization': getCookie('__tw__')
                      },
                      url: `${config.server}/office_types`
                  });
                  if (responseOfficeType.status == 200){
					  handleCheckPublish()
                      responseOfficeType.data.map(async(el,idx)=> {
                          try{
                              const responseTypeHeadQuarter = await axios({
                                  method: 'POST',
                                  headers: {
                                      'Authorization': getCookie('__tw__')
                                  },
                                  url: `${config.server}/addOfficeTypeHeadQuarter`,
                                  data: {
                                      officeTypeId: el.id,
                                      headQuarterId: response.data.data.id
                                  }
                              });
                              if ((responseTypeHeadQuarter.status == 200) && (responseOfficeType.data.length-1 == idx)){
                                  onClose()
                                  fetchCustomers(1,10)
                                  setErrorMessage({'message':'¡Sede creada!, Recuerda publicar tus cambios','color':'#43a047'})
                                  setOpenSnack(true)
                                  setFormState({
                                     name: '',
                                  })
                                  //getTypeOffices()
                              }
                          }catch(e){
                              setLoadingRequest(false)
                              console.log(e)
                          }
                      })

                  }else{
                      setLoadingRequest(false)
                      setErrorMessage({'message':'Error al agregar sede.','color':'#d32f2f'})
                      setOpenSnack(true)
                  }
              }

          }catch(e){
              setLoadingRequest(false)
              setErrorMessage({'message':'Error al agregar sede.','color':'#d32f2f'})
              setOpenSnack(true)
          }
          setLoadingRequest(false)
      }
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };


  /* const hasError = field =>
  formState.touched[field] && formState.errors[field] ? true : false; */

  const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKeyMaps}&libraries=places`

  const originFunction = (address)=>{
    var location = address.description;
    // Get latidude & longitude from address.
    Geocode.fromAddress(location).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        const address = response.results[0].formatted_address;
        const coordinatesMap = {
          'lat': lat,
          'lng': lng,
          'add': address
        }
        setCoordinates(coordinatesMap)
        dispatch({ type: 'map/MODIFY_MAP_MARKER', data: coordinatesMap })
      },
      error => {
        console.error(error);
        alert('Error buscando dirección');
      }
    );
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      {/* <Script
      url = {mapUrl}
    /> */}
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Agregar sede
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={6}
              >
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={handleFieldChange}
                  value={formState.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
              >
              <GooglePlacesAutocomplete
                onSelect={(address)=> originFunction(address)}
                placeholder = "Dirección de Origen"
                inputClassName={classes.autocompleteField}
                suggestionsStyles = {{ container: {}, suggestion: {fontFamily: 'Roboto'}}}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['co'],
                  }
                }}
                required = {true}
              />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}

              >
                  <Typography style={{textAlign: 'center'}}>Haz click en la dirección de la sede</Typography>
                  <Typography style={{textAlign: 'center', color:'#546e7a',fontSize: '11px'}}>Haz Click en el mapa para definir la dirección de la sede.</Typography>
                  <div className={classes.containerMapText}>
                      <div className={classes.containerMapTextItem}>
                          <MapViewEdit 
                          coordinates= {coordinates}
                          setCoordinates = {setCoordinates}
                          latitude={'10.9994565'} longitude={'-74.8044738'}/>
                      </div>
                  </div>
              </Grid>
              <Grid item/>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
        {
            loadingRequest ? (
                <div className='loottieAnimation'>
                    <Lottie
                        options={{
                          animationData: animation
                        }}
                        style={{
                            position: 'fixed',
                            left: '50%',
                            zIndex: 1000,
                            background: 'rgba(0,0,0,0.2)',
                            transform: 'translateX(-50%) translateY(-50%)',
                            top: '50%'
                        }}
                      />
                </div>
            ): (null)
        }
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

CustomerEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerEdit;
