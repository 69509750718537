import React, { useState } from 'react';
import clsx from 'clsx';
import config from '../../../../../../../../config'
import {getCookie} from '../../../../../../../../services/cookies'

import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Switch,
  Button,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const CustomerEdit = props => {
  const { setErrorMessage, setOpenSnack, open, onClose, spaceType, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    ...spaceType
  });

  if (!open) {
    return null;
  }

  const onSave = async() => {
      if ((/^\s*$/.test(formState.name)) || (/^\s*$/.test(formState.address))){
          setErrorMessage({'message':'No puedes dejar valores en blanco.','color':'#d32f2f'})
          setOpenSnack(true)
      }else{
          try{
              const response = await axios({
                  method: 'PUT',
                  headers: {
                      'Authorization': getCookie('__tw__')
                  },
                  url: `${config.server}/office_types/${spaceType.id}`,
                  data: formState
              });
              if (response.status == 200){
                  onClose()
                  fetchCustomers()
              }
          }catch(e){
              console.log(e)
          }
      }
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Editar tipo de espacio
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={handleFieldChange}
                  value={formState.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item/>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

CustomerEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerEdit;
