import React, { useState } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../../../config'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Switch,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Dialog, 
  DialogTitle,
  DialogContent,
  List, 
  ListItem, 
  ListItemText,
  Avatar, 
  ListItemAvatar
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import { Label } from 'components';
import CustomerEdit  from '../../../../../Bookings/components/CustomerEdit';
import CustomerEditPay  from '../../../../../Payments/components/CustomerEdit/CustomerEdit';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CurrencyFormat from 'react-currency-format';
import { FaCheckCircle, FaCalendarDay, FaCalendarWeek, FaCalendar } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'row',    
    alignItems: 'flex-start',
    justifyContent: 'space-around',

    '& > * + *': {
      marginLeft: 0
    }
  },
  encrypt:{
    backgroundColor: '#86a6df',
    color: '#fff',
    padding: '0.5em',
    width: 'max-content',
    fontWeight: '700',
    borderRadius: '0.5em'
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    
  }
}));
console.log("entro aqui CustomerInfodeBookings")
/* const getDataReservaInfo = () => {
  axios.get(`${config.server}/getIndividualBookings/${id}`,{
      headers: {
          'Authorization': getCookie('__tw__')
      },
  }).then(response => {
      setBookings(response.data);
      getCalendarBookings(response.data)
  }).catch(e =>{

  });
}; */

const CustomerInfo = props => {
  const { editDate, getCalendarBookings, disabledDates, setErrorMessage, setOpenSnack, bookings, fetchCustomers, className, ...rest } = props;
  const classes = useStyles();
  const [cantSelect, setCantSelect] = useState("1");
  const [payMethod, setPayMethod] = useState("Daily");
  const [openEdit, setOpenEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false)
  //const [toggleState, setToggleState] = useState(bookings.email_confirmed)
  const [openDialogAddReserva, setOpenDialogAddReserva] = useState(false)

  const handleOpenAddReservaDialog = () => {
     setOpenDialogAddReserva(!openDialogAddReserva)
  }

  const handleEditOpen = () => {
    setOpenEdit(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(!openDialog)
  }
  
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleEditClose = () => {
    setOpenEdit(false);
    fetchCustomers()
  };

  const DialogWrapper = () => {
		return (
			<Dialog onClose={handleCloseDialog} open={openDialog} >
				<DialogTitle style={{ paddingBottom: 0 }}>¿Cómo quieres reservar?</DialogTitle>
				<DialogContent>
					<List>
							<ListItem button onClick={() => {
                handleEditOpen()
								setPayMethod('Daily')
								//handleOpenAddReservaDialog()
                setOpenDialog(false)                
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendarDay />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por día" />
							</ListItem>
						
							<ListItem button onClick={() => {
                setPayMethod('Weekly')
                handleEditOpen()
								//handleOpenAddReservaDialog()
								setOpenDialog(false)
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendarWeek />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por semana" />
							</ListItem>
							<ListItem button onClick={() => {
                setPayMethod('Monthly')
                handleEditOpen()
								//handleOpenAddReservaDialog()
								setOpenDialog(false)
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendar />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por mes" />
							</ListItem>
						
					</List>
				</DialogContent>
			</Dialog>
		)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Reserva info" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre y Apellido</TableCell>
              <TableCell>
                {`${bookings.bookings.name} ${bookings.bookings.lastname}`}
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                    {bookings.bookings.email}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Telefono</TableCell>
                <TableCell>
                    {bookings.bookings.phone}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Fecha inicio</TableCell>
                <TableCell><div className={classes.encrypt}>{bookings.bookings.from_date}</div></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Fecha fin</TableCell>
                <TableCell><div className={classes.encrypt}>{bookings.bookings.to_date}</div></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Oficina</TableCell>
                <TableCell>
                    {bookings.bookings.office_name}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Sede</TableCell>
                <TableCell>
                    {bookings.bookings.head_quarter_name}
                </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={handleOpenDialog}>
          <EditIcon className={classes.buttonIcon} />
          Editar
        </Button>
      </CardActions>
      <DialogWrapper />
      <CustomerEdit
        setErrorMessage={setErrorMessage}
        setOpenSnack={setOpenSnack}
        bookings={bookings}
        getCalendarBookings={getCalendarBookings}
        //disabledDates={disabledDates}
        editDate={editDate}
        onClose={handleEditClose}
        initialFormState={{
          office: bookings.bookings.office_id,
          headQuarter: bookings.bookings.head_quarter_id,
          user: {
            name: bookings.bookings.name,
            lastname: bookings.bookings.lastname,
            email: bookings.bookings.email,
            id: bookings.bookings.users_id
          }
        }}
        open={openEdit}
        fetchCustomers={fetchCustomers}
        cantSelect={cantSelect}
        payMethod={payMethod}
        userName={bookings.name}
      />
      <CustomerEditPay bookings={bookings} open={openDialogAddReserva} onClose={handleOpenAddReservaDialog} bookingsID={bookings.id} setErrorMessage={setErrorMessage} office_id={bookings.office_id} setOpenSnack={setOpenSnack}/>
    </Card>
  );
 
};


CustomerInfo.propTypes = {
  className: PropTypes.string,
  bookings: PropTypes.object.isRequired
};

export default CustomerInfo;
