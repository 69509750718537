import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  colors,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import getInitials from 'utils/getInitials';
import { Label, ReviewStars, GenericMoreButton, TableEditBar } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const Results = props => {
  const { fetchCustomers, willPaginate, className, headQuarters, ...rest } = props;

  const classes = useStyles();

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [page, setPage] = useState(willPaginate.page);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAll = event => {
    const selectedCustomers = event.target.checked
      ? headQuarters.map(headQuarter => headQuarter.id)
      : [];

    setSelectedCustomers(selectedCustomers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomers.indexOf(id);
    let newSelectedCustomers = [];

    if (selectedIndex === -1) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedCustomers, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(1)
      );
    } else if (selectedIndex === selectedCustomers.length - 1) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, selectedIndex),
        selectedCustomers.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomers(newSelectedCustomers);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    fetchCustomers(page+1, rowsPerPage)
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    fetchCustomers(page+1, event.target.value)
  };

  const statusColors = {
	true: [colors.green[600], "Publicado"],
	false: [colors.red[600], "No Publicado"]
  }; 

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {headQuarters.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(headQuarters.length / rowsPerPage)}
      </Typography>
      {
        headQuarters.length > 0 ? (
          <Card>
            <CardHeader
              //action={<GenericMoreButton />}
              title="Todas las sedes"
            />
            <Divider />
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/*<TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedCustomers.length === headQuarters.length}
                            color="primary"
                            indeterminate={
                              selectedCustomers.length > 0 &&
                              selectedCustomers.length < headQuarters.length
                            }
                            onChange={handleSelectAll}
                          />
                        </TableCell>*/}
                        <TableCell>Nombre</TableCell>
                        <TableCell>Dirección</TableCell>
    			          		<TableCell>Publicado</TableCell>
                        <TableCell align="right">Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {headQuarters.slice(0, rowsPerPage).map(headQuarter => (
                        <TableRow
                          hover
                          key={headQuarter.id}
                          selected={selectedCustomers.indexOf(headQuarter.id) !== -1}
                        >
                          {/*<TableCell padding="checkbox">
                            <Checkbox
                              checked={
                                selectedCustomers.indexOf(headQuarter.id) !== -1
                              }
                              color="primary"
                              onChange={event =>
                                handleSelectOne(event, headQuarter.id)
                              }
                              value={selectedCustomers.indexOf(headQuarter.id) !== -1}
                            />
                          </TableCell>*/}
                          <TableCell>{headQuarter.name}</TableCell>
                          <TableCell>{headQuarter.address.add}</TableCell>
    					  <TableCell>
                              <Label
                                color={statusColors[headQuarter.mounted][0]}
                                variant="outlined"
                              >
                                {statusColors[headQuarter.mounted][1]}
                              </Label>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              color="primary"
                              component={RouterLink}
                              size="small"
                              to={`/sede/${headQuarter.id}`}
                              variant="outlined"
                            >
                              Ver
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                  component="div"
                  count={willPaginate.totalRows}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
              />
            </CardActions>
          </Card>
        ): (
          <Typography>No hay datos para mostrar</Typography>
        )
      }

      <TableEditBar selected={selectedCustomers} />
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  headQuarters: PropTypes.array.isRequired
};

Results.defaultProps = {
  headQuarters: []
};

export default Results;
