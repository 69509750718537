import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import config from './../../../../../../config'
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import MaiIcon from '@material-ui/icons/MailOutline';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  sendButton: {
    marginTop: theme.spacing(2)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2)
  },
  cell: {
    padding: theme.spacing(1)
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  warningText: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const SendEmails = props => {
  const { spaceType, fetchCustomers, className, ...rest } = props;
  const classes = useStyles();

  const handleEditOpen = () => {};

  const handleChangeFile = async(event) => {
      let options = {
        method: 'PUT'
      };

      options.body = new FormData();
      options.body.append('feature_image', event.target.files[0])

      try{
		  await fetch(`${config.server}/office_types/${spaceType.id}`,options);
          fetchCustomers()
	  }catch(e){
		  console.log(e)
	  }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Imagen" />
      <Divider />
      <CardContent className={classes.content}>
          <img style={{width:'100%'}} src={`${config.assets}/${spaceType.feature_image_uid}`}/>
      </CardContent>
      <Divider />
          <Typography
              className={classes.warningText}
              variant="body2"
          >
            La resolucion recomendada para esta imagen es de 300x400 pixeles
          </Typography>

      <Divider />
      <CardActions className={classes.actions}>
        <Button onClick={handleEditOpen} component="label">
          <EditIcon className={classes.buttonIcon} />
              <TextField
				  autoFocus
				  margin="dense"
				  id="uploadFeatureImage"
				  label="Titulo del tema"
				  type="file"
				  fullWidth
                  onChange={handleChangeFile}
				  style={{ display: "none" }}
				/>
              Editar
        </Button>
      </CardActions>
    </Card>
  );
};

SendEmails.propTypes = {
  className: PropTypes.string,
  spaceType: PropTypes.object.isRequired
};

export default SendEmails;
