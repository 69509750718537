import axios from 'axios'


export function setInterceptors(router){
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 401) {
            router.history.push('/errors/error-401');
            //place your reentry code
        }
        return error;
    });
}
