import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { Router , BrowserRouter, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import routes from '../../routes';
import config from './../../config'
import {getCookie, delete_cookie} from './../../services/cookies'
import axios from 'axios'
import { NavBar, TopBar, ChatBar } from './components';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const Dashboard = props => {
  const { route } = props;
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const router = useRouter();

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const checkSessionJWT = async() => {
      const response = await axios({
          method: 'GET',
          headers: {
              'Authorization': getCookie('__tw__')
          },
          url: `${config.server}/checkAdmin`,
      });
      if(response.data.status != 200){
          delete_cookie('__tw__')
          router.history.push('/auth/login');
      }
  }

  useEffect(()=>{
      checkSessionJWT()
  },[])

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
			  {
  				routes[2].routes.map((route,idx)=>{
					return(
						<Route
			              key={route.path}
			              path={route.path}
						  exact={true}
						  render={(props) => {
      						  return React.cloneElement(route.component, {match:props.match, history: props.history})
						  }}
			            />
					)
  				})
  			  }
          </Suspense>
        </main>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
