import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import config from '../../../../config'
import {getCookie} from '../../../../services/cookies'
import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Select from 'react-select'
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Lottie from 'lottie-react-web'
import animation from './../../../../animations/loading-cowork2.json'
import {
  Modal,
  Card,
  MenuItem,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Switch,
  Button,
  colors
} from '@material-ui/core';
import CryptoJS from 'crypto-js'

const customSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '.9rem',
    color: '#263238'
  }),
  menuPortal: styles => ({ ...styles, zIndex: 10000000 })
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  containerCalendar:{
      marginTop: theme.spacing(1)
  }
}));

const CustomerEdit = props => {
  const { setErrorMessage, setOpenSnack, open, onClose, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date())

  const [formState, setFormState] = useState({
      code: '',
      percent: ''
  });
  const [loadingRequest, setLoadingRequest] = useState(false)

  const handleChangeDate = (value) => {
	  setSelectedDate(value)
  }

  const onSave = async() => {
      setLoadingRequest(true)
      if ((/^\s*$/.test(formState.percent))){
			 setErrorMessage({'message':'No puedes dejar valores en blanco.','color':'#d32f2f'})
             setOpenSnack(true)
	  }else{
          if(parseInt(formState.percent) >= 0 && parseInt(formState.percent) <= 40){
              let crypto = CryptoJS.SHA3(`${Date.now()}`,{ outputLength: 48 }).toString()
    		  try{
                  const response = await axios({
                      method: 'POST',
                      headers: {
                          'Authorization': getCookie('__tw__')
                      },
                      url: `${config.server}/promos`,
                      data: {
                          code: crypto,
    					  expired_date: selectedDate,
                          percent: formState.percent
                      }
                  });
    			 if(response.status == 201){
    				  setErrorMessage({'message':'Codigo creado!','color':'#43a047'})
    				  setOpenSnack(true)
    				  setFormState({
    					  code: '',
                          percent: ''
                      })
    				  setSelectedDate(new Date())
    				  onClose()
    				  fetchCustomers(1,10)
    			  }else{
    				  setErrorMessage({'message':'Error al crear el codigo!','color':'#d32f2f'})
    				  setOpenSnack(true)
    			  }
    		  }catch(e){
    			  console.log(e)
    		  }
          }else{
              setErrorMessage({'message':'El porcentaje debe ser entre 0 y 40.','color':'#d32f2f'})
              setOpenSnack(true)
          }
      }
      setLoadingRequest(false)
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  useEffect(()=> {
  },[])

  if (!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      id="portal-target"
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Agregar codigo
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
			  <Grid
				item
				md={6}
				xs={12}
			  >
			  <Typography>Fecha de vencimiento del codigo</Typography>
			   <DatePicker style={{width:'100%', height:'100%'}} value={selectedDate} onChange={handleChangeDate} />
			  </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
					type="number"
                    onChange={handleFieldChange}
                    label="Porcentaje de descuento 0-40%"
                    name="percent"
                    value={formState.percent}
                    variant="outlined"/>
              </Grid>
              <Grid item/>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
        {
            loadingRequest ? (
                <div className='loottieAnimation'>
                    <Lottie
                        options={{
                          animationData: animation
                        }}
                        style={{
                            position: 'fixed',
                            left: '50%',
                            zIndex: 1000,
                            background: 'rgba(0,0,0,0.2)',
                            transform: 'translateX(-50%) translateY(-50%)',
                            top: '50%'
                        }}
                      />
                </div>
            ): (null)
        }
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

CustomerEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerEdit;
