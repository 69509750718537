import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import {getCookie} from './../../../../../../services/cookies'
import config from './../../../../../../config'
import axios from 'axios';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = props => {
  const { handleCheckPublish, activatePublish, fetchCustomers, setOpenSnack, setErrorMessage, className, handleEditOpen, ...rest } = props;

  const classes = useStyles();

  const handlePublishChanges = async() => {
	try{
		const response = await axios({
			method: 'POST',
			headers: {
				'Authorization': getCookie('__tw__')
			},
			url: `${config.server}/upload_changes_offices`
		});
		if(response.status == 200){
			fetchCustomers(1,10);
			handleCheckPublish()
			setErrorMessage({'message':'Los cambios se estan publicando, se paciente.','color':'#43a047'})
			setOpenSnack(true)
		}
	}catch(e){

	}
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Gestor
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            Espacios
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            onClick={handleEditOpen}
            variant="contained"
          >
            Agregar espacio
          </Button>
		  <Button
            color="primary"
			disabled={!activatePublish}
			style={{marginLeft:10}}
            onClick={handlePublishChanges}
            variant="contained"
          >
            Publicar Cambios
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
