import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import config from './../../config'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import { Tabs, Tab, Typography, Divider, colors, Snackbar, SnackbarContent } from '@material-ui/core';
import {setInterceptors} from './../../services/axios'
import useRouter from 'utils/useRouter';
import { Page } from 'components';
import { Header, Summary, Fare, Files } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  errorMessage:{
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
  },
  iconError:{
	marginRight: '0.5em',
  }
}));

const SpaceDetail = (props) => {
  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const router = useRouter();
  setInterceptors(router)

  const [spaceData, setSpaceData] = useState();
  const [spaceImages, setSpaceImages] = useState();
  const [spaceFares, setSpaceFares] = useState();

  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
	  'message': '',
	  'color': ''
  })

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'summary', label: 'Resumen' },
    { value: 'fare', label: 'Tarifas' },
    { value: 'images', label: 'Imagenes' },
  ];

  const fetchCustomers = () => {
    axios.get(`${config.server}/getOffices/${id}`).then(response => {
        console.log(response.data)
		if(response.data.feature_image_uid == null){
			response.data.feature_image_uid = 'oficinas_placeholder.png'
		}
        setSpaceData(response.data);
    });
  };

  const fetchImages = () => {
    axios.get(`${config.server}/images_by_id/${id}`).then(response => {
        setSpaceImages(response.data);
    });
  };


  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

  useEffect(() => {
	fetchCustomers();
	fetchImages();
  },[]);

  if (!tab) {
    return <Redirect to={`/espacio/${id}/summary`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (<>
      {
          typeof spaceData !== 'undefined' ? (
              <Page
                className={classes.root}
                title="Detalles Sede"
              >
                <Header spaceData={spaceData} />
                <Tabs
                  className={classes.tabs}
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  value={tab}
                  variant="scrollable"
                >
                  {tabs.map(tab => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
                <Divider className={classes.divider} />
                <div className={classes.content}>
                  {tab === 'summary' && <Summary history={history} fetchCustomers={fetchCustomers} spaceData={spaceData} setErrorMessage={setErrorMessage} setOpenSnack={setOpenSnack}/>}
                  {tab === 'images' && typeof spaceImages != 'undefined' && <Files fetchImages={fetchImages} spaceData={spaceData} files={spaceImages} setErrorMessage={setErrorMessage} setOpenSnack={setOpenSnack} />}
                  {tab === 'fare' && typeof spaceData != 'undefined' && <Fare spaceData={spaceData} fetchCustomers={fetchCustomers} setErrorMessage={setErrorMessage} setOpenSnack={setOpenSnack}/>}
                </div>
				<SnackBarWrapper message={errorMessage.message}/>
              </Page>
          ):(
              <p>Cargando...</p>
          )
      }
  </>);
};

export default SpaceDetail;
