import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {Card,Avatar,CardActions,CardContent,CardHeader,Checkbox,Divider,Button,Link,Table,TableBody,TableCell,colors,TableHead,TablePagination,TableRow,Typography,InputAdornment,TextField,FormControlLabel} from '@material-ui/core';
import getInitials from 'utils/getInitials';
import { Label, ReviewStars, GenericMoreButton, TableEditBar } from 'components';
import SearchIcon from '@material-ui/icons/Search';
import {DatePicker, KeyboardDatePicker} from "@material-ui/pickers";
import axios from 'axios';
import {getCookie} from '../../../../services/cookies';
import config from '../../../../config';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  encrypt:{
    backgroundColor: '#86a6df',
    color: '#fff',
    padding: '0.5em',
    width: 'max-content',
    fontWeight: '700',
    borderRadius: '0.5em'
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const Results = props => {
  const { fetchCustomers, willPaginate, className, ...rest } = props;
  const classes = useStyles();
  const [search, setSearch] = useState(false);
  const [searchDate, setSearchDate] = useState(false);
  const [searchAmount, setSearchAmount] = useState(false);
  const [searchOffice, setSearchOffice] = useState(false);
  const [dataResponse, setDataResponse] = useState([]);
  //const [selectedDate, setSelectedDate] = useState(new Date())

  const [dataToSearch, setDataToSearch] = useState({
    'search': '',
    'config': '',
    'startDate': new Date(),
    'endDate': new Date(),
  });
  const [checkBoxState, setCheckBoxState] = useState(true);




  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [page, setPage] = useState(willPaginate.page);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState();
  const handleSelectAll = event => {
  const selectedCustomers = event.target.checked
      ? dataResponse.map(payment => payment.id)
      : [];

    setSelectedCustomers(selectedCustomers);
  };
 

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomers.indexOf(id);
    let newSelectedCustomers = [];

    if (selectedIndex === -1) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedCustomers, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(1)
      );
    } else if (selectedIndex === selectedCustomers.length - 1) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, selectedIndex),
        selectedCustomers.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomers(newSelectedCustomers);
  };
  
  console.log("DataTOsearch", dataToSearch)

  const checkBox  = name => event => {
    setCheckBoxState(!checkBoxState)
    setSearch(!search)
    setDataToSearch({...dataToSearch, [name]: event.target.value})
  }

  const viewCheckBox = () => {
    setCheckBoxState(!checkBoxState)
    setSearch(false)
    setSearchDate(false)
  }

  const clicSearchDate = (event) => {
    setSearchDate(!searchDate)
    setCheckBoxState(!checkBoxState)
    setDataToSearch({...dataToSearch, config: event.target.value})
  }
  
  const handleChangeDateStartDate = (event) => {
    setDataToSearch({...dataToSearch, startDate: event})
  }
  const handleChangeDateEndDate = (event) => {
    setDataToSearch({...dataToSearch, endDate: event})
  }

  const handleChangePage = (event, page) => {
    setPage(page);
    fetchCustomers(page+1, rowsPerPage)
  };

  const handleFieldChangeSearch = event => {
    console.log("Epalexz", event.target.value)
    setDataToSearch({...dataToSearch, search: event.target.value})
  }

  const searchBotton = async() => {
    let page = 1
    let perPage = 10
    //console.log("Epalexz", event.target.value)
        try{
          const response = await axios({
            method: 'POST',
            headers: {
                'Authorization': getCookie('__tw__')
            },
            url: `${config.server}/searchPayment?page=${page}&per_page=${perPage}`,
            data: {
              search: dataToSearch.search,
              config: dataToSearch.config,
              startDate: dataToSearch.startDate,
              endDate: dataToSearch.endDate,
          }
        }); if (response) {
          console.log("response ", response)
            setDataResponse(response.data.payments)
        }
       
       
      }catch(e){}
  }
  console.log("response PaySearch", dataResponse)

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    fetchCustomers(page+1, event.target.value)
  };

  const paymentStatusColors = {
    sucess: colors.grey[600],
    pending: colors.orange[600],
    true: colors.green[600],
    false: colors.red[600]
  };

  useEffect(()=> {
    searchBotton(1, 10)
},[])

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {dataResponse.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(dataResponse.length / rowsPerPage)}
      </Typography>
      <Card
        style={{marginBottom: "5px"}}
      >
      {
          checkBoxState ?
        <CardHeader
          //action={<GenericMoreButton />}
          title="Seleccione modo de busqueda"
        />
        :
          null
        }
        {
            checkBoxState ?
          <FormControlLabel
            id='user'
            onChange={checkBox('config')}
            style={{marginLeft: "5px"}}
            control={<Checkbox color="primary" />}
            label="Nombre - Email - Metodo - Codigo de pago"
            labelPlacement="Nombre"
            value="user"
          />
          :
          null
        }
        {
          checkBoxState ?
          <FormControlLabel
            id='office'
            onChange={checkBox('config')}
            style={{marginLeft: "5px"}}
            control={<Checkbox color="primary" />}
            label="Sede u Oficina"
            labelPlacement="Sede u Oficina"
            value="office"
          />
          :
          null
        }
        {
          checkBoxState ?
          <FormControlLabel
            id='date'
            onChange={clicSearchDate}
            style={{marginLeft: "5px"}}
            control={<Checkbox color="primary" />}
            label="Fechas"
            labelPlacement="Fechas"
            value="date"
          />
          :
          null
        }
        { 
          checkBoxState ?
          <FormControlLabel
            id='amount'
            onChange={checkBox('config')}
            style={{marginLeft: "5px"}}
            control={<Checkbox color="primary" />}
            label="Monto"
            labelPlacement="Monto"
            value="amount"
          />
          :
          null
        }
            {search ?
              <TextField
              style={{margin: "5px"}}
              onChange={handleFieldChangeSearch}
              label="Buscar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              //value={searchValue}
              variant="outlined"/>
              :
              null
            }
            {
              searchDate ?
              <div style={{ margin: '5px', marginLeft: "10px"}}>
              <DatePicker style={{ marginLeft: "20px"}}   margin="normal" id="Desde" label="Desde" value={dataToSearch.startDate} onChange={handleChangeDateStartDate}  />
              <DatePicker style={{ marginLeft: "20px"}}   margin="normal" id="Hasta" label="Hasta" value={dataToSearch.endDate} onChange={handleChangeDateEndDate}  />
              </div>
              :
              null
            }      
            {
              searchOffice || searchAmount || searchDate || search ?
              <div>
                <Button
                style={{margin: "5px"}}
                onClick={searchBotton}
                color="primary"
                variant="contained"
              >
                Buscar
              </Button>
              <Button
                style={{margin: "5px"}}
                onClick={viewCheckBox}
                color="danger"
                variant="contained"
              >
                Cancelar
              </Button>
              </div>
              :
              null
            }
            
      </Card>
      <Divider />
      {
        dataResponse.length > 0 ? (
          <Card>
            <CardHeader
              //action={<GenericMoreButton />}
              title="Todos los pagos"
            />
            <Divider />
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  < Table>
                    <TableHead>
                      <TableRow>
                        {/*<TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedCustomers.length === payments.length}
                            color="primary"
                            indeterminate={
                              selectedCustomers.length > 0 &&
                              selectedCustomers.length < payments.length
                            }
                            onChange={handleSelectAll}
                          />
                        </TableCell>*/}
                        <TableCell>Nombre y Apellido</TableCell>
                        <TableCell>Fecha de pago</TableCell>
                        <TableCell>Codigo de pago</TableCell>
                        <TableCell>Metodo</TableCell>
                        <TableCell>Cantidad</TableCell>
						            <TableCell>Sede</TableCell>
                        <TableCell>Espacio</TableCell>
                        <TableCell align="right">Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataResponse.slice(0, rowsPerPage).map(payment => (
                        <TableRow
                          hover
                          key={payment.id}
                          selected={selectedCustomers.indexOf(payment.id) !== -1}
                        >
                          {/*<TableCell padding="checkbox">
                            <Checkbox
                              checked={
                                selectedCustomers.indexOf(payment.id) !== -1
                              }
                              color="primary"
                              onChange={event =>
                                handleSelectOne(event, payment.id)
                              }
                              value={selectedCustomers.indexOf(payment.id) !== -1}
                            />
                          </TableCell>*/}
                          <TableCell>{`${payment.name} ${payment.lastname}`}</TableCell>
                          <TableCell><div className={classes.encrypt}>{payment.date}</div></TableCell>
                          <TableCell>{payment.transaction_id}</TableCell>
                          <TableCell>{payment.payment_method}</TableCell>
                          <TableCell>${payment.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</TableCell>
                          <TableCell>{payment.head_quarter_name}</TableCell>
                          <TableCell>{payment.office_name}</TableCell>
                          <TableCell align="right">
                            <Button
                              color="primary"
                              component={RouterLink}
                              size="small"
                              to={`/pagos/${payment.id}`}
                              variant="outlined"
                            >
                              Ver
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                  component="div"
                  count={willPaginate.totalRows}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
              />
            </CardActions>
          </Card>
        ): (
            <Typography> No hay datos para mostrar </Typography>
        )
      }

      <TableEditBar selected={selectedCustomers} />
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  //payments: PropTypes.array.isRequired,
  dataResponse: PropTypes.array.isRequired
};

Results.defaultProps = {
  payments: []
};

export default Results;
