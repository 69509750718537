/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OverviewView from './views/Overview';
import HeadQuarters from 'views/HeadQuarters'
import Publicaciones from 'views/Publicaciones'
import HeadQuartersDetail from 'views/HeadQuartersDetail'
import SpaceType from './views/SpaceType';
import SpaceTypeDetail from './views/SpaceTypeDetail';
import SpaceDetail from './views/SpaceDetail';
import Users from 'views/Users'
import UsersDetail from 'views/UsersDetail'
import Bookings from 'views/Bookings'
import BookingsDetail from 'views/BookingsDetail'
import Payments from 'views/Payments'
import PaymentsDetail from 'views/PaymentsDetail'
import PromosDetail from 'views/PromosDetail'
import Promos from 'views/Promos'
import Calendar from 'views/Calendar'
import Login from 'views/Login'
import Error404 from 'views/Error404'
import Error500 from 'views/Error500'
import Error401 from 'views/Error401'

const routes = [
  /*{
    path: '/',
    exact: true,
    component: <Redirect to="/presentation" />
  },*/
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: <Login/>
      },
      /*{
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }*/
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
		component: <Error401/>
      },
      {
        path: '/errors/error-404',
        exact: true,
		component: <Error404/>
      },
      {
        path: '/errors/error-500',
        exact: true,
		component: <Error500/>
      },
      /*{
        component: () => <Redirect to="/errors/error-404" />
      }*/
    ]
  },
  {
    path: '*',
    component: DashboardLayout,
    routes: [
        {
            path: '/overview',
            exact: true,
            component: <OverviewView/>
        },
        {
            path: '/sedes',
            exact: true,
            component: <HeadQuarters/>
        },
        {
            path: '/sede/:id',
            exact: true,
            component: <HeadQuartersDetail/>
        },
        {
            path: '/sede/:id/:tab',
            exact: true,
            component: <HeadQuartersDetail/>
        },
        {
            path: '/tipos_espacios',
            exact: true,
            component: <SpaceType/>
        },
        {
            path: '/tipos_espacio/:id',
            exact: true,
            component: <SpaceTypeDetail/>
        },
        {
            path: '/tipos_espacio/:id/:tab',
            exact: true,
            component: <SpaceTypeDetail/>
        },
        {
            path: '/espacio/:id',
            exact: true,
            component: <SpaceDetail/>
        },
        {
            path: '/espacio/:id/:tab',
            exact: true,
            component: <SpaceDetail/>
        },
        {
            path: '/usuarios',
            exact: true,
            component: <Users/>
        },
        {
            path: '/usuario/:id',
            exact: true,
            component: <UsersDetail/>
        },
        {
            path: '/usuario/:id/:tab',
            exact: true,
            component: <UsersDetail/>
        },
        {
            path: '/reservas',
            exact: true,
            component: <Bookings/>
        },
        {
            path: '/reserva/:id',
            exact: true,
            component: <BookingsDetail/>
        },
        {
            path: '/reserva/:id/:tab',
            exact: true,
            component: <BookingsDetail/>
        },
        {
            path: '/eventos',
            exact: true,
            component: <Calendar/>
        },
		{
            path: '/pagos',
            exact: true,
            component: <Payments/>
        },
		{
            path: '/pagos/:id',
            exact: true,
            component: <PaymentsDetail/>
        },
        {
            path: '/pagos/:id/:tab',
            exact: true,
            component: <PaymentsDetail/>
        },
        {
            path: '/promos',
            exact: true,
            component: <Promos/>
        },
        {
            path: '/publicaciones',
            exact: true,
            component: <Publicaciones/>
        },
        {
            path: '/promos/:id',
            exact: true,
            component: <PromosDetail/>
        },
        {
            path: '/promos/:id/:tab',
            exact: true,
            component: <PromosDetail/>
        },
		/*{
            path: '*',
            exact: false,
            component: <Redirect to="/errors/error-404" />
        },*/
    ]
  }
];

export default routes;
