import React, {useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import config from '../../../../../../config'
import {getCookie} from '../../../../../../services/cookies'
import axios from 'axios'
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/styles';
import {Card,CardHeader,CardContent,CardActions,Dialog,DialogHeader,DialogTitle,DialogContent,DialogContentText,TextField,MenuItem,Select,DialogActions,Button,Divider,Grid,Table,TableHead,TableBody,TableRow,TableCell,InputAdornment,Typography} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import GetAppIcon from '@material-ui/icons/GetApp';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CurrencyFormat from 'react-currency-format';
import EditIcon from '@material-ui/icons/Edit';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Lottie from 'lottie-react-web'
import animation from './../../../../../../animations/loading-cowork2.json'  
import InputBase from '@material-ui/core/InputBase';
import {DatePicker} from "@material-ui/pickers";

const BootstrapInput = withStyles(theme  =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles(theme => ({
  root: {},
  mainActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notice: {
    marginBottom: theme.spacing(1)
  },
  encrypt: ({menssages}) => {
    var valueReserva = {
      backgroundColor: '',
      color: '#fff',
      padding: '0.5em',
      width: 'max-content',
      fontWeight: '700',
      borderRadius: '0.5em'
    }   
    switch (menssages) {
      case "pagado": 
          return {...valueReserva, backgroundColor: '#2E9909'}
      case "Pendiente_pago": 
        return {...valueReserva, backgroundColor: '#E17F02'}
      case "nada_pagado": 
        return {...valueReserva, backgroundColor: '#C51B00'}
      default:
        return valueReserva
      break;
    }
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const PayCard = props => {
  const { history, setErrorMessage, setOpenSnack, bookings, className, fetchCustomers, ...rest } = props;
  const [openEdit, setOpenEdit] = useState(false); 
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [statusReservePay, setStatusReservePay] = useState('')
  const classes = useStyles(bookings);
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogAddReserva, setOpenDialogAddReserva] = useState(false)
  const [openDialogPays, setOpenDialogPays] = useState(false)
  const [payMethodEfective, setPayMethodEfective] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [referenceCode, setRefenceCode] = useState()
  const [amountToPay, setAmountToPay] = useState()

  const setStatusReservePayFun = (book) => {       
    switch (book.menssages) {
      
      case "pagado": console.log("book.menssages",book.menssages)
          return setStatusReservePay("Estado de pago - PAGADA!")
      case "Pendiente_pago": 
        return setStatusReservePay("Estado de pago - PENDIENTE!")
      case "nada_pagado": 
        return setStatusReservePay("Estado de pago - NINGUN PAGO!")
      default:
        return null
      break;
    }
  };  
  console.log("entro aqui paycard")
  const handleDeleteHeadQuarter = async() => {
      try{
          const response = await axios({
              method: 'DELETE',
              headers: {
                  'Authorization': getCookie('__tw__')
              },
              url: `${config.server}/bookings/${bookings.id}`,
          })
          if(response.data.state == "SUCCESS"){
              history.push('/reservas')
          }else{
              setErrorMessage({'message':'Error al eliminar esta reserva.','color':'#d32f2f'})
              setOpenSnack(true)
              setOpenDialog(false)
          }
      }catch(e){
          console.log(e)
      }
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
  };
 
 const amountToPayShow = () => (
  <TableRow>
    <TableCell>Monto con descuento</TableCell>
      <TableCell>
        <div className={classes.encrypt}><CurrencyFormat style={{fontWeight:'600', fontSize:'20px'}} value={bookings.bookings.amount_with_discount} displayType={'text'}  thousandSeparator={true} prefix={'$'}/></div>
      </TableCell>
  </TableRow>
  )
    
    
  
  console.log("bookings",bookings)
  const onSave = async() => {
    setLoadingRequest(true)
  if (payMethodEfective == '' ){
    setErrorMessage({'message':'Debes ingresar la forma de pago.','color':'#d32f2f'})
    setOpenSnack(true)
  }
  else if(typeof amountToPay !== "undefined" == '' ) {
    setErrorMessage({'message':'Debes ingresar un monto a pagar.','color':'#d32f2f'})
    setOpenSnack(true)
  }
  else{
    try{
        const response = await axios({
          method: 'POST',
          headers: {
              'Authorization': getCookie('__tw__')
          },
          url: `${config.server}/createPayment`,
          data: {
            amount: amountToPay,
            booking_id: bookings.bookings.id,
            user_id: bookings.bookings.user_id,
            date: selectedDate,
            transaction_id: referenceCode,
            method_pay: payMethodEfective,
        }
      }); 
      if(response.data.message == "ALREADY_PAY"){
        setErrorMessage({'message':'Ya existe un pago para esta reserva!','color':'#d32f2f'})
        setOpenSnack(true)
      }else if(response.data.message == "DOESNT_EXIST"){
        setErrorMessage({'message':'Este ID de reserva no existe, por favor verifica el ID en el gestor de reservas!','color':'#d32f2f'})
        setOpenSnack(true)
      }else if(response.data.message == "SUCCESS"){
        setErrorMessage({'message':'Pago creado!','color':'#43a047'})
        console.log("Si =llego a fetchCustomers")
        await fetchCustomers(bookings.bookings.id)
        setOpenSnack(true)
        setRefenceCode('')
        payMethodEfective('')
        setSelectedDate(new Date())
        setAmountToPay('')
        setStatusReservePayFun(bookings)
      }
      else{
        setErrorMessage({'message':`${response.data.message}`,'color':'#d32f2f'})
        setOpenSnack(true)
      }
    }catch(e){
    }
  }
    setLoadingRequest(false)
}


  const handleChangeDate =  (value) => {
    console.log("value ", value)
    setSelectedDate(value)
  }
  const handleChangepayMethodEfective = event => {
    setPayMethodEfective(event.target.value)
  }
  
  const handleFieldChangeAmounToPay = event => {
    setAmountToPay(event.target.value)
  }

  const handleFieldChangeReferenseCode = event => {
    setRefenceCode(event.target.value)
  }

  const handleOpenPaysDialog = () => {
    setOpenDialogPays(true)
  }

  useEffect(() => {
    setStatusReservePayFun(bookings);
    },[]);
console.log("payments",bookings.paymentsAll)
  const DialogWrapper = () => {
      return (<Dialog
        open={openDialogPays}
        onClose={()=>setOpenDialogPays(false)}
        style={{paddingTop: "none"}}
      >
        <DialogTitle>{"Estos son los pagos realizados"}</DialogTitle>
        <DialogContent >
          <Table >        
            <TableHead>
              <TableRow>
              <TableCell>Codigo de Referencia</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Metodo</TableCell>
              </TableRow>    
            </TableHead>
            <TableBody>
                {
                  bookings.paymentsAll.map(payments => (
                    <TableRow
                    hover
                    key={payments.id}
                    >
                      <TableCell>{payments.transaction_id}</TableCell>
                      <TableCell>{payments.date}</TableCell>
                      <TableCell><CurrencyFormat style={{fontWeight:'500'}} value={payments.amount} displayType={'text'}  thousandSeparator={true} prefix={'$'}/></TableCell>
                      <TableCell>{payments.payment_method}</TableCell>
                    </TableRow>   
                  ))
                }
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenDialogPays(false)} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>)
  }

  return (
    <Card
    {...rest}
    className={clsx(classes.root, className)}
  >
    <CardHeader title={statusReservePay} />
    
    <CardContent className={classes.content}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Valor de la Reserva</TableCell>
            <TableCell>
            <div className={classes.encrypt}><CurrencyFormat style={{fontWeight:'600', fontSize:'20px'}} value={bookings.bookings.amount} displayType={'text'}  thousandSeparator={true} prefix={'$'}/></div>
            </TableCell>
          </TableRow>
            {bookings.bookings.amount_with_discount !== 0 && amountToPayShow()}          
          <TableRow>
              <TableCell>Pendiente por pagar</TableCell>
              <TableCell>
              <CurrencyFormat style={{fontWeight:'600', fontSize:'20px'}} value={bookings.pending} displayType={'text'}  thousandSeparator={true} prefix={'$'}/>
              </TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Forma de pago</TableCell>
              <TableCell
                style={{
                  padding:'inherit'
                }
              }
              >
                <Select
                  fullWidth
                  
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={payMethodEfective}
                  onChange={handleChangepayMethodEfective}
                  input={<BootstrapInput />}
                >
                  <MenuItem disabled value="">
                    <em>Ingrese la forma de pago</em>
                  </MenuItem>
                  <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
                  <MenuItem value={"Tarjeta de credito"}>Tarjeta de credito</MenuItem>
                  <MenuItem value={"Tarjeta de Debito"}>Tarjeta de Debito</MenuItem>
                  <MenuItem value={"Transferencia bancaria"}>Transferencia bancaria</MenuItem>
                </Select>
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Codigo de referencia</TableCell>
              <TextField
                fullWidth
                onChange={handleFieldChangeReferenseCode}
                name="amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VerifiedUserIcon />
                    </InputAdornment>
                  ),
                }}
                value={referenceCode}
                variant="outlined"/>
          </TableRow>
          <TableRow>
            <TableCell>Ingresar pago</TableCell>
              <TextField
                fullWidth
                style={{
                    marginTop:'10px'
                  }
                }
                type="number"
                onChange={handleFieldChangeAmounToPay}
                label="Cantidad"
                name="amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MonetizationOnIcon />
                    </InputAdornment>
                  ),
                }}
                value={amountToPay}
                variant="outlined"/>
          </TableRow>
          <TableRow>
          <TableCell>Fecha de pago</TableCell>
            <DatePicker style={{ marginTop: '10px', width:'100%', height:'100%'}} value={selectedDate} onChange={handleChangeDate} />
          </TableRow> 
        </TableBody>
      </Table>
    </CardContent>
    <CardActions className={classes.actions}>
      <Button onClick={handleEditOpen}>
        <EditIcon className={classes.buttonIcon} />
        Editar
      </Button>
      <Button onClick={onSave}>
        <CreditCardIcon className={classes.buttonIcon} />
        Pagar
      </Button>
      <Button onClick={handleOpenPaysDialog}>
        <CreditCardIcon className={classes.buttonIcon} />
        Ver pagos
      </Button>
    </CardActions>
    <DialogWrapper/>
    {
      loadingRequest ? (
          <div className='loottieAnimation'>
              <Lottie
                  options={{
                    animationData: animation
                  }}
                  style={{
                      position: 'fixed',
                      left: '50%',
                      zIndex: 1000,
                      background: 'rgba(0,0,0,0.2)',
                      transform: 'translateX(-50%) translateY(-50%)',
                      top: '50%'
                  }}
                />
          </div>
      ): (null)
    }
  </Card>
  );
};

/* OtherActions.propTypes = {
  className: PropTypes.string
}; */

export default PayCard;
