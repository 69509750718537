import React, { useState } from 'react';
import clsx from 'clsx';
import config from '../../../../config'
import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Lottie from 'lottie-react-web'
import animation from './../../../../animations/loading-cowork2.json'
import {getCookie} from './../../../../services/cookies'

import {
  Modal,
  Card,
  MenuItem,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Switch,
  Button,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const CustomerEdit = props => {
  const {setErrorMessage, setOpenSnack, open, onClose, users, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
      name: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      user_type: '',
  });
  const [loadingRequest, setLoadingRequest] = useState(false)


  if (!open) {
    return null;
  }

  const onSave = async() => {
    console.log(formState)
      if ((/^\s*$/.test(formState.name)) ||
         (/^\s*$/.test(formState.lastname)) ||
         (/^\s*$/.test(formState.email)) ||
         (/^\s*$/.test(formState.phone)) ||
         (/^\s*$/.test(formState.password)) ||
         (/^\s*$/.test(formState.user_type))){
          setErrorMessage({'message':'No puedes dejar valores en blanco.','color':'#d32f2f'})
          setOpenSnack(true)
      }else if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formState.email)){
		setErrorMessage({'message':'Email invalido.','color':'#d32f2f'})
		setOpenSnack(true)
	  }else{
          setLoadingRequest(true)
          try{
              const response = await axios({
                  method: 'POST',
                  headers: {
                      'Authorization': getCookie('__tw__')
                  },
                  url: `${config.server}/users`,
                  data: formState
              });
              console.log(response)
              if (response.data.state == "SUCCESS"){
                  onClose()
                  fetchCustomers(1,10)
                  setErrorMessage({'message':'¡Usuario creado!','color':'#43a047'})
                  setOpenSnack(true)
                  setFormState({
                     name: '',
                     lastname: '',
                     email: '',
                     phone: '',
                     password: '',
                     user_type: '',
                  })
              }else if(response.data.state == "EMAIL_REPEAT"){
                  setErrorMessage({'message':'Error, email ya existe!','color':'#d32f2f'})
                  setOpenSnack(true)
              }
          }catch(e){
              console.log(e)
          }
          setLoadingRequest(false)
      }
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };


  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Agregar usuario
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={handleFieldChange}
                  value={formState.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Apellido"
                  name="lastname"
                  onChange={handleFieldChange}
                  value={formState.lastname}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  onChange={handleFieldChange}
                  value={formState.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  type="number"
                  onChange={handleFieldChange}
                  value={formState.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  onChange={handleFieldChange}
                  value={formState.password}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
              <TextField
                    select
                    fullWidth
                    onChange={handleFieldChange}
                    label="Tipo de usuario"
                    name="user_type"
                    value={formState.user_type}
                    variant="outlined">
                        <MenuItem value="client">Cliente</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
              </TextField>
              </Grid>
              <Grid item/>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
        {
            loadingRequest ? (
                <div className='loottieAnimation'>
                    <Lottie
                        options={{
                          animationData: animation
                        }}
                        style={{
                            position: 'fixed',
                            left: '50%',
                            zIndex: 1000,
                            background: 'rgba(0,0,0,0.2)',
                            transform: 'translateX(-50%) translateY(-50%)',
                            top: '50%'
                        }}
                      />
                </div>
            ): (null)
        }
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

CustomerEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerEdit;
