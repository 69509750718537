import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import MapView from './../../../../../../components/MapView';
import { Label } from 'components';
import { CustomerEdit } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  containerMapText:{
      padding: '1em'
  },
  containerMapTextItem:{
      position: 'relative',
      width: '100%',
      height: '200px'
  }
}));

const CustomerInfo = props => {
  const { setErrorMessage, setOpenSnack, headQuarterData, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Sede info" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre y Apellido</TableCell>
              <TableCell>
                {headQuarterData.name} {headQuarterData.lastname}
              </TableCell>
            </TableRow>
			<TableRow selected>
              <TableCell>Email </TableCell>
              <TableCell>
				  {headQuarterData.email}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cantidad </TableCell>
              <TableCell>
				  ${headQuarterData.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
              </TableCell>
            </TableRow>
			<TableRow selected>
              <TableCell>ID de la reserva </TableCell>
              <TableCell>
				  {headQuarterData.booking_id}
              </TableCell>
            </TableRow>
			<TableRow>
              <TableCell>Sede </TableCell>
              <TableCell>
				  {headQuarterData.head_quarter_name}
              </TableCell>
            </TableRow>
			<TableRow selected>
              <TableCell>Oficina</TableCell>
              <TableCell>
				  {headQuarterData.office_name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      {/*<CardActions className={classes.actions}>
        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Editar
        </Button>
      </CardActions>
      <CustomerEdit
        setErrorMessage={setErrorMessage}
        setOpenSnack={setOpenSnack}
        headQuarterData={headQuarterData}
        onClose={handleEditClose}
        open={openEdit}
        fetchCustomers={fetchCustomers}
      />*/}
    </Card>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  headQuarterData: PropTypes.object.isRequired
};

export default CustomerInfo;
