/*const config = {
	server: 'http://localhost:3000',
	assets: 'http://localhost:3000/assets',
	//server: 'https://apicw.desarrollodeprueba.com',
	//assets: 'https://apicw.desarrollodeprueba.com/assets'
}

export default config*/

let config;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	console.log("OK")
	config = {
		server: 'http://localhost:3000',
		frontend: 'http://localhost:8000',
		socketServer: 'ws://localhost:3000',
		assets: 'http://localhost:3000/assets',
		local: 'https://localhost:3001/images'
	}
	/*config = {
		server: 'https://api.coworkentretodos.com',
		frontend: 'https://www.coworkentretodos.com',
		socketServer: 'ws://api.coworkentretodos.com',
		assets: 'https://api.coworkentretodos.com/assets',
		local:  'https://admin.coworkentretodos.com/static/media'
	}*/
} else {
	config = {
		server: 'https://api.coworkentretodos.com',
		frontend: 'https://www.coworkentretodos.com',
		socketServer: 'wss://api.coworkentretodos.com',
		assets: 'https://api.coworkentretodos.com/assets',
		local:  'https://admin.coworkentretodos.com/static/media'
	}
}

export default config
