import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  colors,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import getInitials from 'utils/getInitials';
import { Label, ReviewStars, GenericMoreButton, TableEditBar } from 'components';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },

  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  encrypt:{
    backgroundColor: '#86a6df',
    color: '#fff',
    padding: '0.5em',
    width: 'max-content',
    fontWeight: '700',
    borderRadius: '0.5em',
    margin: '0px'
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const Results = props => {
  const { fetchCustomers, payMethod, willPaginate, className, bookings, ...rest } = props;
  const classes = useStyles();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [page, setPage] = useState(willPaginate.page);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAll = event => {
    const selectedCustomers = event.target.checked
      ? bookings.map(booking => booking.id)
      : [];

    setSelectedCustomers(selectedCustomers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomers.indexOf(id);
    let newSelectedCustomers = [];

    if (selectedIndex === -1) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedCustomers, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(1)
      );
    } else if (selectedIndex === selectedCustomers.length - 1) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, selectedIndex),
        selectedCustomers.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomers(newSelectedCustomers);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    fetchCustomers(page+1, rowsPerPage)
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    fetchCustomers(page+1, event.target.value)
  };
  
  const changeColorPayed = (state, amount) => (
    <div className={classes.encrypt} style={{backgroundColor: state == "pagado" ? "green" : "red"}}>
      <CurrencyFormat value={amount} displayType={'text'}  thousandSeparator={true} prefix={'$'} />
    </div>
  );
  
  const paymentStatusColors = {
    sucess: colors.grey[600],
    pending: colors.orange[600],
    true: colors.green[600],
    false: colors.red[600]
  };
  console.log("bookings EMA",bookings)
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {bookings.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(bookings.length / rowsPerPage)}
      </Typography>
      {
        bookings.length > 0 ? (
          <Card>
            <CardHeader
              //action={<GenericMoreButton />}
              title="Todas las reservas"
            />
            <Divider />
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/*<TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedCustomers.length === bookings.length}
                            color="primary"
                            indeterminate={
                              selectedCustomers.length > 0 &&
                              selectedCustomers.length < bookings.length
                            }
                            onChange={handleSelectAll}
                          />
                        </TableCell>*/}
						<TableCell>ID</TableCell>
                        <TableCell>Nombre y Apellido</TableCell>      
                        <TableCell>Periodo</TableCell>
                        
                        <TableCell>Oficina</TableCell>
                        <TableCell>Sede</TableCell>
                        <TableCell>Monto</TableCell>
                        <TableCell>Monto Pagado</TableCell>
                        <TableCell align="right">Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bookings.slice(0, rowsPerPage).map(booking => (
                        <TableRow
                          hover
                          key={booking.id}
                          selected={selectedCustomers.indexOf(booking.id) !== -1}
                        >
						              <TableCell>{booking.id}</TableCell>
                          <TableCell>{`${booking.name} ${booking.lastname}`}</TableCell>
                          <TableCell><div className={classes.encrypt}>Del <Moment format="D MMM YYYY" withTitle>{booking.from_date}</Moment> al <Moment format="D MMM YYYY" withTitle>{booking.to_date}</Moment></div></TableCell>
                          
                          <TableCell>{booking.office_name}</TableCell>
                          <TableCell>{booking.head_quarter_name}</TableCell>
                          <TableCell>{
                            booking.amount_with_discount != 0 ?
                              changeColorPayed(booking.state, booking.amount_with_discount)                             
                            : 
                              changeColorPayed(booking.state, booking.amount)
                            }
                          </TableCell>
                          <TableCell><CurrencyFormat value={booking.amount_payed} displayType={'text'}  thousandSeparator={true} prefix={'$'} /></TableCell>
                          <TableCell align="right">
                            <Button
                              color="primary"
                              component={RouterLink}
                              size="small"
                              to={`/reserva/${booking.id}`}
                              variant="outlined"
                            >
                              Ver
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                      
                      )}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                  component="div"
                  count={willPaginate.totalRows}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
              />
            </CardActions>
          </Card>
        ): (
            <Typography> No hay datos para mostrar </Typography>
        )
      }

      <TableEditBar selected={selectedCustomers} />
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
