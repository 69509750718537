import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { Router , BrowserRouter, Route} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import routes from '../../routes';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const Error = props => {
  const { route } = props;
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
		  {
			routes[1].routes.map((route,idx)=>{
				return(
					<Route
					  key={route.path}
					  path={route.path}
					  exact={true}
					  render={(props) => {
						  return React.cloneElement(route.component, {match:props.match, history: props.history})
					  }}
					/>
				)
			})
		  }
      </Suspense>
    </main>
  );
};

Error.propTypes = {
  route: PropTypes.object
};

export default Error;
