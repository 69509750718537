import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import config from '../../../../../../../../config'
import axios from 'axios'
import PropTypes from 'prop-types';
import {getCookie} from '../../../../../../../../services/cookies'

import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  Button,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
  },
  miniContainer:{
      display: 'flex',
      justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  controlLabel:{
    width: '30%',
    marginBottom: '1em'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  [theme.breakpoints.down('sm')]: {
    controlLabel:{
      width: '100%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    controlLabel:{
      justifyContent: 'center'
    }
  }
}));

const SpaceEdit = props => {
  const { open, onClose, setErrorMessage, setOpenSnack, spaceData, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    ...spaceData
  });

  const [checkeds,setCheckeds] = useState([])
  const [newBenefit, setNewBenefit] = useState('')
  const [benefits, setBenefits] = useState([])


  const autoBenefits = () => {
      let tempArr = []
      spaceData.benefits.map((el,idx)=>{
          tempArr.push({
              name: el,
              checked: true
          })

          if(idx == spaceData.benefits.length-1){
              setBenefits(tempArr)
          }
      })
  }


  const onSave = async() => {
      let newBenefits = {};
      newBenefits.benefits = [];
      benefits.map((el,idx)=>{
          if(el.checked == true){
              newBenefits.benefits.push(el.name);
          }
      })
      //setFormState(newBenefits)
      try{
          const response = await axios({
              method: 'PUT',
              headers: {
                  'Authorization': getCookie('__tw__')
              },
              url: `${config.server}/update_benefits/${spaceData.id}`,
              data: {benefits: newBenefits.benefits}
          });
          if (response.status == 200){
              onClose()
              fetchCustomers()
              setBenefits([])
              autoBenefits()
          }
      }catch(e){
          console.log(e)
      }
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const handleChangeNewBenefit = event => {
      setNewBenefit(event.target.value)
  }

  const setInitialCheckeds = () => {
      const tempCheck = [...benefits]
      benefits.map((el,idx)=> {
          if(spaceData.benefits.find((ofty)=>ofty===el.name)){
              tempCheck[idx].checked = true
              setBenefits(tempCheck)
          }else{
              tempCheck[idx].checked = false
              setBenefits(tempCheck)
          }
      })
  }

  const handleChecked = (idx) => {
      const tempCheck = [...benefits]
      tempCheck[idx].checked = !tempCheck[idx].checked
      setBenefits(tempCheck)
  }

  const handleAddBenefit = () => {
      if (!(/^\s*$/.test(newBenefit))){
          let tempArr = benefits
          tempArr.push({
              name: newBenefit,
              checked: true
          })
          setBenefits(tempArr)
          setNewBenefit('')
      }

  }

  useEffect(()=>{
      //setInitialCheckeds()
      autoBenefits()
  },[spaceData.benefits])


  if (!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Editar beneficios
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
            {
                benefits.length > 0 ? (
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{textAlign:'center'}}
                    >
                    {
      				  benefits.map((el,idx)=> {
      					  return(
      						  <FormControlLabel
      							  className={classes.controlLabel}
      							  key={idx}
      							  control={
      								  <Checkbox
      									  color="primary"
      									  checked={el.checked}
      									  onChange={() => handleChecked(idx)}
      									  />
      							  }
      							  label={el.name}
      						   />
      					  )
      				  })

                    }
                    </Grid>
                ):(
                    <p>Cargando...</p>
                )
            }

              <Grid item/>
            </Grid>
            <Grid
              className={classes.miniContainer}
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
                style={{textAlign:'center'}}
              >
              <TextField
                fullWidth
                label="Agregar nuevo..."
                name="newBenefit"
                onChange={handleChangeNewBenefit}
                value={newBenefit}
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddBenefit}
                style={{margin:'1em'}}
                endIcon={<Icon>send</Icon>}
              >
                Agregar
              </Button>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

SpaceEdit.displayName = 'SpaceEdit';

SpaceEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SpaceEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default SpaceEdit;
