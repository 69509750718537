import React, { useState, useEffect, useRef, Fragment } from 'react'
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button, Snackbar, SnackbarContent, Dialog, DialogTitle,
	DialogContent, List, ListItem, ListItemText, Avatar, ListItemAvatar, TextField, MenuItem,
	CardContent, Card} from '@material-ui/core';
import { FaCheckCircle, FaCalendarDay, FaCalendarWeek, FaCalendar } from 'react-icons/fa';
import CustomerEdit from '../CustomerEdit';

const useStyles = makeStyles(() => ({
  root: {},

  iconError: {
	marginRight: '0.5em'
  }
  
}));

const Header = props => {
  const { className, handleEditOpen, fetchCustomers, ...rest } = props;
  const [payMethod, setPayMethod] = useState('Monthly')
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogAddReserva, setOpenDialogAddReserva] = useState(false)
  const classes = useStyles(
	{
		root: {},
	  
		iconError: {
		  marginRight: '0.5em'
		}
		
	  }
  );
  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    'message': '',
    'color': ''
  })

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog)
  }
  
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleOpenAddReservaDialog = () => {
    setOpenDialogAddReserva(true)
  }
  const handleCloseAddReservaDialog = () => {
    setOpenDialogAddReserva(!openDialogAddReserva)
  }


  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
									<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}<Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}
  const DialogWrapper = () => {
		return (
			<Dialog onClose={handleCloseDialog} open={openDialog} >
				<DialogTitle style={{ paddingBottom: 0 }}>¿Cómo quieres reservar?</DialogTitle>
				<DialogContent>
					<List>
							<ListItem button onClick={() => {
                				handleEditOpen()
								setPayMethod('Daily')
								handleOpenAddReservaDialog()
               					setOpenDialog(false)                
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendarDay />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por día" />
							</ListItem>
						
							<ListItem button onClick={() => {
								setPayMethod('Weekly')
								handleOpenAddReservaDialog()
								setOpenDialog(false)
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendarWeek />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por semana" />
							</ListItem>
							<ListItem button onClick={() => {
								setPayMethod('Monthly')
								handleOpenAddReservaDialog()
								setOpenDialog(false)
							}}>
								<ListItemAvatar>
									<Avatar>
										<FaCalendar />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary="Por mes" />
							</ListItem>
						
					</List>
				</DialogContent>
			</Dialog>
		)
  }
  
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Gestor
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            Reservas
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            onClick={handleOpenDialog}
            variant="contained"
          >
            Agregar reserva
          </Button>
        </Grid>
      </Grid>
      <DialogWrapper />
      <CustomerEdit open={openDialogAddReserva} onClose={handleCloseAddReservaDialog} payMethod={payMethod}  fetchCustomers={fetchCustomers} setOpenSnack={setOpenSnack} setErrorMessage={setErrorMessage}/>
	  <SnackBarWrapper message={errorMessage.message}/>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  
};

export default Header;