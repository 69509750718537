import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timelinePlugin from '@fullcalendar/timeline';
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import esLocale from '@fullcalendar/core/locales/es';


import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  SnackbarContent,
  Snackbar,
  Typography,
  Card,
  CardContent,
  colors,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import config from './../../config'
import uuid from 'uuid/v1';
import {setInterceptors} from './../../services/axios'
import {getCookie} from './../../services/cookies'
import useRouter from 'utils/useRouter';
import axios from 'axios';
import { Page } from 'components';
import { AddEditEvent, Toolbar } from './components';
import Lottie from 'lottie-react-web'
import animation from './../../animations/loading-cowork2.json'

const useStyles = makeStyles(theme => ({
  errorMessage:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconError:{
      marginRight: '0.5em',
  },
  root: {
    height: '100%',
    padding: theme.spacing(3),
    '& .fc-unthemed td': {
      borderColor: theme.palette.divider
    },
    '& .fc-widget-header': {
      backgroundColor: colors.grey[50]
    },
    '& .fc-axis': {
      ...theme.typography.body2
    },
    '& .fc-list-item-time': {
      ...theme.typography.body2
    },
    '& .fc-list-item-title': {
      ...theme.typography.body1
    },
    '& .fc-list-heading-main': {
      ...theme.typography.h6
    },
    '& .fc-list-heading-alt': {
      ...theme.typography.h6
    },
    '& .fc th': {
      borderColor: theme.palette.divider
    },
    '& .fc-day-header': {
      ...theme.typography.subtitle2,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: colors.grey[50]
    },
    '& .fc-day-top': {
      ...theme.typography.body2
    },
    '& .fc-highlight': {
      backgroundColor: colors.blueGrey[50]
    },
    '& .fc-event': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderWidth: 2,
      opacity: 0.9,
      '& .fc-time': {
        ...theme.typography.h6,
        color: 'inherit'
      },
      '& .fc-title': {
        ...theme.typography.body1,
        color: 'inherit'
      }
    },
    '& .fc-list-empty': {
      ...theme.typography.subtitle1
    }
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

const Calendar = () => {
  const es = esLocale;
  const classes = useStyles();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const router = useRouter();
  setInterceptors(router)
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = useState(mobileDevice ? 'listWeek' : 'dayGridMonth');
  const [date, setDate] = useState(moment().toDate());
  const [events, setEvents] = useState([]);
  const [eventModal, setEventModal] = useState({
    open: false,
    event: null
  });
  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    'message': '',
    'color': ''
  })

  const [loadingRequest, setLoadingRequest] = useState(false)

  const fetchEvents = () => {
    axios.get(`${config.server}/getAllEventsWithoutLimit`).then(response => {
        let formatEvents = []
        for(var i = 0; i < response.data.length; i++) {
            formatEvents.push({});
        }
        for(let i = 0;i < response.data.length;i++){
            formatEvents[i].id = response.data[i].id
            formatEvents[i].title = response.data[i].event_name;
            formatEvents[i].desc = response.data[i].event_type;
            formatEvents[i].headQuarter = response.data[i].head_quarter_name;
            formatEvents[i].headQuarterId = response.data[i].head_quarter_id;
            formatEvents[i].start = moment(`${response.data[i].date}`).toDate();
            formatEvents[i].allDay = true
        }
        setEvents(formatEvents);
    });
  };

  useEffect(() => {
      let mounted = true;

      if (mounted) {
          fetchEvents();
      };

        return () => {
          mounted = false;
        };
  }, []);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const newView = mobileDevice ? 'listWeek' : 'dayGridMonth';

    calendarApi.changeView(newView);
    setView(newView);
  }, [mobileDevice]);

  const handleEventClick = info => {
    const selected = events.find(event => parseInt(event.id) === parseInt(info.event.id));
    setEventModal({
      open: true,
      event: selected
    });
  };

  const handleEventNew = () => {
    setEventModal({
      open: true,
      event: null
    });
  };

  const handleEventDelete = async event => {
    setEventModal({
      open: false,
      event: null
    });
    setLoadingRequest(true)
    try{
        const response = await axios({
            method: 'DELETE',
            headers: {
                'Authorization': getCookie('__tw__')
            },
            url: `${config.server}/events/${event.id}`
        });
        setErrorMessage({'message':'Evento eliminado!','color':'#43a047'})
        setOpenSnack(true)
        fetchEvents()
    }catch(e){

    }

    setLoadingRequest(false)
  };

  const handleModalClose = () => {
    setEventModal({
      open: false,
      event: null
    });
  };

  const handleEventAdd = async event => {
    //setEvents(events => [...events, event]);
    setEventModal({
      open: false,
      event: null
    });
    setLoadingRequest(true)
    try{
        const response = await axios({
            method: 'POST',
            url: `${config.server}/events`,
            headers: {
                'Authorization': getCookie('__tw__')
            },
            data: {
                name: event.title,
                date: event.start,
                event_type: event.desc,
                head_quarter_id: event.headQuarterId
            }
        });
        console.log(response)
        setErrorMessage({'message':'Evento creado!','color':'#43a047'})
        setOpenSnack(true)
        fetchEvents()
    }catch(e){

    }
    setLoadingRequest(false)
  };

  const handleEventEdit = async event => {
    //setEvents(events => events.map(e => (e.id === event.id ? event : e)));
    setEventModal({
      open: false,
      event: null
    });
    setLoadingRequest(true)
    try{
        const response = await axios({
            method: 'PUT',
            headers: {
                'Authorization': getCookie('__tw__')
            },
            url: `${config.server}/events/${event.id}`,
            data: {
                name: event.title,
                date: event.start,
                event_type: event.desc,
                head_quarter_id: event.headQuarterId
            }
        });
        setErrorMessage({'message':'Evento editado!','color':'#43a047'})
        setOpenSnack(true)
        fetchEvents()
    }catch(e){

    }
    setLoadingRequest(false)
  };

  const handleDateToday = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.today();
    setDate(calendarApi.getDate());
  };

  const handleViewChange = view => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.changeView(view);
    setView(view);
  };

  const handleDatePrev = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
    setDate(calendarApi.getDate());
  };

  const handleDateNext = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    setDate(calendarApi.getDate());
  };

  const handleEventDrop = async ({event}) => {
      try{
          const response = await axios({
              method: 'PUT',
              headers: {
                  'Authorization': getCookie('__tw__')
              },
              url: `${config.server}/events/${event.id}`,
              data: {
                  date: event.start
              }
          });
          fetchEvents()
      }catch(e){

      }
  }

  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

  return (
      <Page
        className={classes.root}
        title="Calendar"
      >
        <Toolbar
          date={date}
          onDateNext={handleDateNext}
          onDatePrev={handleDatePrev}
          onDateToday={handleDateToday}
          onEventAdd={handleEventNew}
          onViewChange={handleViewChange}
          view={view}
        />
        <Card className={classes.card}>
          <CardContent>
              <FullCalendar
                
                allDayMaintainDuration
                defaultDate={date}
                defaultView={view}
                locale={es}
                droppable
                eventDrop={handleEventDrop}
                editable
                eventClick={handleEventClick}
                eventResizableFromStart
                events={events}
                header={false}
                height={800}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  timelinePlugin
                ]}
                ref={calendarRef}
                rerenderDelay={10}
                selectable
                weekends
              />
          </CardContent>
        </Card>
        <Modal
          onClose={handleModalClose}
          open={eventModal.open}
        >
          <AddEditEvent
            event={eventModal.event}
            onAdd={handleEventAdd}
            setOpenSnack={setOpenSnack}
            setErrorMessage={setErrorMessage}
            onCancel={handleModalClose}
            onDelete={handleEventDelete}
            onEdit={handleEventEdit}
          />
        </Modal>
        <SnackBarWrapper message={errorMessage.message}/>
            {
                loadingRequest ? (
                    <div className='loottieAnimation'>
                        <Lottie
                            options={{
                              animationData: animation
                            }}
                            style={{
                                position: 'fixed',
                                left: '50%',
                                zIndex: 1000,
                                background: 'rgba(0,0,0,0.2)',
                                transform: 'translateX(-50%) translateY(-50%)',
                                top: '50%'
                            }}
                          />
                    </div>
                ): (null)
            }
      </Page>
  );
};

export default Calendar;
