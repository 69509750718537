import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  colors
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

import { Label } from 'components';
import { SpaceEdit } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(0)
  },
}));

const Invoices = props => {
  const { headQuarterData, fetchCustomers, setErrorMessage, setOpenSnack, className, ...rest } = props;
  const classes = useStyles();

  const handleChipDelete = chip => {
	//setChips(chips => chips.filter(c => chip !== c));
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Tipos de espacio" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
				{
					headQuarterData.office_type.map((chip,idx) => (
                        <TableRow key={idx} selected={idx%2==0}>
    						<TableCell>
    							<Chip
    								className={classes.chip}
    								deleteIcon={<CloseIcon />}
    								key={chip.name}
    								label={chip.name}
    								//onDelete={() => handleChipDelete(chip)}
    							/>
    						</TableCell>
                        </TableRow>
					))
				}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Editar
        </Button>
      </CardActions>
      <SpaceEdit
	  	setErrorMessage={setErrorMessage}
	  	setOpenSnack={setOpenSnack}
        headQuarterData={headQuarterData}
        onClose={handleEditClose}
        open={openEdit}
        fetchCustomers={fetchCustomers}
      />
    </Card>
  );
};

Invoices.propTypes = {
  className: PropTypes.string,
  headQuarterData: PropTypes.object.isRequired
};

export default Invoices;
