import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { SnackbarContent, Snackbar } from '@material-ui/core';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import config from '../../../../config'
import { getCookie } from '../../../../services/cookies'
import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Select from 'react-select'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './calendarStyle.css'
import { DateRangePicker } from 'react-date-range';
import Lottie from 'lottie-react-web'
import animation from './../../../../animations/loading-cowork2.json'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import {
  Modal,
  Card,
  MenuItem,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Switch,
  Button,
  colors,
  DialogTitle,
  InputAdornment
} from '@material-ui/core';
import es from "date-fns/locale/es";
import styles from './office.module.scss'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { FaBookOpen } from 'react-icons/fa';

const customSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '.9rem',
    color: '#263238'
  }),
  menuPortal: styles => ({ ...styles, zIndex: 10000000 })
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    marginTop: theme.spacing(3),
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  containerCalendar: {
    marginTop: theme.spacing(1)
  }
}));

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function getDates(startDate, stopDate) {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}


const CustomerEdit = (props) => {

  const { bookings, setErrorMessage, payMethod, initialFormState, setOpenSnack, open, onClose, fetchCustomers, className, ...rest } = props;
  console.log("bookings", bookings)


  const classes = useStyles();
  const [users, setUsers] = useState();
  const [headQuarters, setHeadQuarters] = useState();
  const [offices, setOffices] = useState();
  const [usersOptions, setUsersOptions] = useState([]);
  const [cantSelect, setCantSelect] = useState("1");
  const [promoCode, setPromoCode] = useState('')
  const [promoCodeData, setPromoCodeData] = useState('');
  const [userName, setUserName] = useState('')
  const dispatch = useDispatch()
  const [officeDisplay, setOfficeDisplay] = useState(false);
  const [calendarDisplay, setCalendarDisplay] = useState(false);
  const [values, setValues] = useState({
    Monthly: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    Weekly: ["1", "2", "3"],
    Daily: ["1", "2", "3", "4", "5", "6"]
  }
  );

  const [errorMessage2, setErrorMessage2] = useState({
    'message': '',
    'color': ''
  });

  const [openSnack2, setOpenSnack2] = useState(false);
  const [dataDisableDays, setDataDisableDays] = useState('');
  const [resumenCompra, setResumenCompra] = useState({
    amount: 0,
    amount_with_discount: 0,
    discount: 0,
  });

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  function handleChange(event) {
    setCantSelect(parseInt(event.target.value))
  };

  const [formState, setFormState] = useState({
    user: '',
    headQuarter: '',
    office: '',
    ...initialFormState
  });

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [officeData, setOfficeData] = useState();

console.log("formState",formState)

  const getAmount = (ranges) => {
    var amount = ""
    const Difference_In_Time = ranges.endDate.getTime() - ranges.startDate.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (payMethod == "Monthly") {
      amount = officeData.monthly * ((Difference_In_Days + 1) / 30)
    } else if (payMethod == "Weekly") {
      amount = officeData.weekly * ((Difference_In_Days + 1) / 7)
    } else if (payMethod == "Daily") {
      amount = officeData.daily * (Difference_In_Days + 1)
    }

    if (promoCodeData.percent) {
      var discount = (amount * promoCodeData.percent) / 100
      var amount_with_discount = amount - discount

      setResumenCompra({ ...resumenCompra, amount, amount_with_discount, discount })
    } else {
      setResumenCompra({ ...resumenCompra, amount })
    }
  }


  const hadleChangePromo = (event) => {
    setPromoCode(event.target.value)
    handleContinue(event.target.value)
  }
  const getPromosCode = async (idUser) => {
    try {
      const response = await fetch(`${config.server}/getCodesByUser/${idUser.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const promosCodes = await response.json();
      dispatch({ type: 'user/SET_PROMOS_CODE', data: promosCodes })
    } catch (e) {
    }
  }
  const postSaveCodeUsed = async () => {
    try {
      const response = await axios(`${config.server}/saveCodeUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          user_id: { id: formState.user.value }.id,
          promo_id: promoCodeData.id
        }
      })
      const responseSaveCode = await response.json();
    } catch (e) {
    }
  }

  const handleContinue = async (codeSave) => {
    if (/^\s*$/.test(codeSave)) {
      //getAmount()

      /* 			setDateRange({
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection',
            }) */
    } else {
      try {
        const response = await axios({
          url: `${config.server}/checkCodeUser`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: { id: formState.user.value }.id,
            code: codeSave.toLowerCase()
          }
        })
        const promosCodes = response
        if (promosCodes.data.message == "DOESNT_EXIST") {
          setErrorMessage2({ 'message': 'El codigo de promocion es invalido.', 'color': '#d32f2f' })
          setOpenSnack2(true)
          setPromoCodeData('')
        } else if (promosCodes.data.message == "ALREADY_USED") {
          setErrorMessage2({ 'message': 'Ya has usado este codigo de promocion.', 'color': '#d32f2f' })
          setOpenSnack2(true)
          setPromoCodeData('')
        } else if (promosCodes.data.message == "SUCCESS") {
          setErrorMessage2({ 'message': 'Codigo Correcto.', 'color': '#43a047' })
          setOpenSnack2(true)
          if (new Date(promosCodes.data.data.expired_date) > new Date()) {
            setPromoCodeData(promosCodes.data.data)
            getPromosCode({ id: formState.user.value })
            //getAmount()

            setDateRange({
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection',
            })

            //Save promoCode on Back


          } else {
            setErrorMessage2({ 'message': 'Este codigo ya expiro.', 'color': '#d32f2f' })
            setOpenSnack2(true)
          }
        }
      } catch (e) {
      }
    }
  }

  const onSave = async () => {
    setLoadingRequest(true)
    try {
      const response = await fetch(`${config.server}/createBooking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          dateRange: dateRange,
          userData: { id: formState.user.id },
          office: formState.office,
          amount: `${resumenCompra.amount}`,
          amount_with_discount: resumenCompra.amount_with_discount
        }),
      })
      const responseData = await response.json()
      if (responseData.state == "SUCCESS") {

        onClose()
        fetchCustomers(1, 10)
        setErrorMessage({ 'message': 'Reserva creada!', 'color': '#43a047' })
        //getCalendarBookings(formState.office)
        setOpenSnack(true)
        postSaveCodeUsed()
        setFormState({
          user: '',
          headQuarter: '',
          office: ''
        })
        setOfficeDisplay(false)
        setCalendarDisplay(false)
      } else {
        onClose()
        fetchCustomers(1, 10)
        setErrorMessage({ 'message': 'Error al reservar!', 'color': '#d32f2f' })
        setOpenSnack(true)
        setFormState({
          user: '',
          headQuarter: '',
          office: ''
        })
        setOfficeDisplay(false)
        setCalendarDisplay(false)
      }
    } catch (e) {
    }
    setLoadingRequest(false)
  }
console.log("daeRAngeees",dateRange)

  const onUpdate = async () => {
    console.log("hey estas en onUpdateeeeeeeeeeeee")
    setLoadingRequest(true)
    try {
      const response = await fetch(`${config.server}/updateBooking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: formState.user.id,
          office_id: formState.office,
          from_date: dateRange.startDate,
          to_date: dateRange.endDate,
          amount: resumenCompra.amount,
          amount_with_discount: resumenCompra.amount_with_discount,
          booking_id: bookings.bookings.id
        }),
      })
      const responseData = await response.json()
      if (responseData.state == "SUCCESS") {

        onClose()
        fetchCustomers(1, 10)
        setErrorMessage({ 'message': 'Reserva Actualizada!', 'color': '#43a047' })
        //getCalendarBookings(formState.office)
        setOpenSnack(true)
        postSaveCodeUsed()
        setFormState({
          user: '',
          headQuarter: '',
          office: ''
        })
        setOfficeDisplay(false)
        setCalendarDisplay(false)
      } else {
        onClose()
        fetchCustomers(1, 10)
        setErrorMessage({ 'message': 'Error al reservar!', 'color': '#d32f2f' })
        setOpenSnack(true)
        setFormState({
          user: '',
          headQuarter: '',
          office: ''
        })
        setOfficeDisplay(false)
        setCalendarDisplay(false)
      }
    } catch (e) {
    }
    setLoadingRequest(false)
  }

  const SnackBarWrapper = ({ message }) => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnack2}
        autoHideDuration={6000}
        onClose={() => setOpenSnack2(!openSnack2)}
      >
        <SnackbarContent
          style={{ backgroundColor: errorMessage2.color }}
          message={
            <span className={classes.errorMessage2}>
              {
                errorMessage2.color == "#d32f2f" ? (
                  <IoIosWarning size={20} className={classes.iconError} />
                ) : (
                    errorMessage2.color == "#43a047" ? (
                      <IoIosCheckmarkCircle size={20} className={classes.iconError} />
                    ) : (
                        <p>Nada</p>
                      )
                  )
              }
              <Typography style={{ color: '#fff' }}>{message}</Typography>
            </span>
          }
          onClose={() => setOpenSnack(!openSnack2)}
          variant="error"
        />
      </Snackbar>
    )
  }

	const getDisableDays = async (id) => {
		try {
			const response = await fetch(`${config.server}/checkAvailability/${id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json();
			setDataDisableDays(data)
		} catch (e) {
			console.log(e)
		}
	}


  const handleCalendarChange = (ranges) => {

    if (payMethod === "Monthly") {
      let count = 0;
      let semana = 29;

      let sumaDias = semana * cantSelect;

      ranges.selection.endDate = (ranges.selection.startDate).addDays(sumaDias)

      const dateFormat = 'YYYY-MM-DD';
      const diff = moment(ranges.selection.endDate).diff(ranges.selection.startDate, 'days') + 1;

      var daysDis = [];

      for (let a = 0; a < dataDisableDays.ranges.length; a++) {
        daysDis.push(moment(dataDisableDays.ranges[a]).format(dateFormat));
      }

      for (let i = 0; i < diff; i++) {
        const checkDate = moment(ranges.selection.startDate).add(i, 'd').format(dateFormat);

        if (daysDis.indexOf(checkDate) !== -1) {
          count++;
        }
      }

      let addMonthly = (count + cantSelect) - 1
      /* se asigna valor a rangeselectionDate */
      if (count) {
        setErrorMessage2({ 'message': 'Debes seleccionar un rango distinto, hay dias  deshabilitados.', 'color': '#d32f2f' })
        setTimeout(
          function () {
            setOpenSnack2(true);
          }
            .bind(this),
          500
        );
      } else {
        ranges.selection.endDate = (ranges.selection.startDate).addDays((sumaDias) + addMonthly)

        setDateRange(ranges.selection)
        getAmount(ranges.selection)
      }

    } else if (payMethod === "Weekly") {

      let count = 0;
      let semana = 6;

      let sumaDias = semana * cantSelect;

      ranges.selection.endDate = (ranges.selection.startDate).addDays(sumaDias)

      const dateFormat = 'YYYY-MM-DD';
      const diff = moment(ranges.selection.endDate).diff(ranges.selection.startDate, 'days') + 1;



      var daysDis = [];

      for (let a = 0; a < dataDisableDays.ranges.length; a++) {
        daysDis.push(moment(dataDisableDays.ranges[a]).format(dateFormat));
      }

      for (let i = 0; i < diff; i++) {
        const checkDate = moment(ranges.selection.startDate).add(i, 'd').format(dateFormat);

        if (daysDis.indexOf(checkDate) !== -1) {
          count++;
        }

      }

      var addWeekly = (count + cantSelect) - 1

      if (count) {
        setErrorMessage2({ 'message': 'Debes seleccionar un rango distinto, hay dias  deshabilitados.', 'color': '#d32f2f' })
        setTimeout(
          function () {
            setOpenSnack2(true);
          }
            .bind(this),
          500
        );
      } else {

        /* se asigna valor a rangeselectionDate */

        ranges.selection.endDate = (ranges.selection.startDate).addDays((sumaDias) + addWeekly)

        setDateRange(ranges.selection)
        getAmount(ranges.selection)

      }



    } else if (payMethod === "Daily") {
      let count = 0;
      let semana = 1;

      let sumaDias = semana * cantSelect;

      ranges.selection.endDate = (ranges.selection.startDate).addDays(sumaDias)

      const dateFormat = 'YYYY-MM-DD';
      const diff = moment(ranges.selection.endDate).diff(ranges.selection.startDate, 'days');


      var daysDis = [];

      for (let a = 0; a < dataDisableDays.ranges.length; a++) {
        daysDis.push(moment(dataDisableDays.ranges[a]).format(dateFormat));
      }

      for (let i = 0; i < diff; i++) {
        const checkDate = moment(ranges.selection.startDate).add(i, 'd').format(dateFormat);

        if (daysDis.indexOf(checkDate) !== -1) {
          count++;
        }

      }

      if (count) {
        setErrorMessage2({ 'message': 'Debes seleccionar un rango distinto, hay dias  deshabilitados.', 'color': '#d32f2f' })
        setTimeout(
          function () {
            setOpenSnack2(true);
          }
            .bind(this),
          500
        );
      }
      else {
        /* se asigna valor a rangeselectionDate */

        ranges.selection.endDate = (ranges.selection.startDate).addDays((sumaDias) + count - 1)

        setDateRange(ranges.selection)
        getAmount(ranges.selection)
      }
    }

  }


  const handleChangeUser = (data) => {
    setFormState(formState => ({
      ...formState,
      user: data
    }));
  }

  const handleFieldChange = event => {
    if (event.target.name == "headQuarter") {
      setOfficeDisplay(true)
      getAllOffice(event.target.value)
      //setFormState({...formState, user: event.target.value})
    } else if (event.target.name == "office") {
      setCalendarDisplay(true)
      console.log("event.target.value",event.target.value)
      getOfficeData(event.target.value)
      //getCalendarBookings(event.target.value)
      getDisableDays(event.target.value)
      
    }
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };
console.log("OfficeData", officeData)
  const getAllUsers = () => {
    axios.get(`${config.server}/users`, {
      headers: {
        'Authorization': getCookie('__tw__')
      },
    }).then(response => {
      setUsers(response.data);
      setUsersOptions(response.data)
    }).catch(e => {

    });
  }

  const getAllHeadQuarters = () => {
    try {
      axios.get(`${config.server}/head_quarters`).then(response => {
        setHeadQuarters(response.data);
      });
    } catch (e) {
      console.log(e)
    }
  }
  
  const getAllOffice = (headQuarterId) => {
    try {
      axios.get(`${config.server}/getOfficeByHeadQuarter?headQuarterId=${headQuarterId}`).then(response => {
        setOffices(response.data);
      });
    } catch (e) {
      console.log(e)
    }
  }
console.log("days disabled",dataDisableDays)
  const getCalendarBookings = async (officeId) => {

    try {
      const response = await fetch(`${config.server}/getBookingsOffice?office_id=${officeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const fetchData = await response.json()
      let disabledDatesArr = []
      //setDisabledDates(disabledDatesArr)
      fetchData.map((el, idx) => {
        disabledDatesArr = [...disabledDatesArr, ...getDates(new Date(el.from_date.replace(/-/g, ',')), new Date(el.to_date.replace(/-/g, ',')))]
        if (idx == fetchData.length - 1) {
          //setDisabledDates(disabledDatesArr)
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getOfficeData = async (officeId) => {
    try {
      const response = await axios(`${config.server}/getOffices/${officeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      setOfficeData(response.data)
    } catch (e) {
      console.log(e)
    }
  }


  const handleBookingCheck = () => {

    if ((/^\s*$/.test(formState.user)) ||
      (/^\s*$/.test(formState.headQuarter)) ||
      (/^\s*$/.test(formState.office))) {
      setErrorMessage({ 'message': 'No puedes dejar valores en blanco.', 'color': '#d32f2f' })
      setOpenSnack(true)

    }
    else if (resumenCompra.monto == "0") {
      setErrorMessage({ 'message': 'El valor de la reserva no puede ser 0.', 'color': '#d32f2f' })
      setOpenSnack(true)
    }
     else if (bookings != undefined ){
      onUpdate()
    } 

    else {

      onSave()
      
    }
  }

  useEffect(() => {
    getAllUsers()
    getAllOffice(formState.headQuarter)
    getAllHeadQuarters()
    getOfficeData(formState.office)
    getPromosCode()
    getDisableDays(formState.office)
  }, [])

  if (!open) {
    return null;
  }

  
  return (
    <Modal
      onClose={onClose}
      open={open}
      id="portal-target"
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              { bookings != undefined ? <p>Editar Reserva</p> : <p>Agregar Reserva</p> }
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                {
                  usersOptions.length > 0 ? (
                    <Select
                      menuPortalTarget={document.body}
                      styles={customSelectStyles}
                      getOptionLabel={(option) => `${option.name} ${option.lastname} - ${option.email}`}
                      getOptionValue={(option) => option.id}
                      onChange={handleChangeUser}
                      value={formState.user}
                      placeholder="Buscar usuario..."
                      name="user"
                      options={usersOptions}
                    />
                  ) : (
                      <p>Cargando,,,</p>
                    )
                }

                {/*<TextField
                select
                fullWidth
                onChange={handleFieldChange}
                label="Usuario"
                name="user"
                value={formState.user}
                variant="outlined">
                    {
                        users.map((el,idx)=>(
                            <MenuItem value={el.id}>{`${el.name} ${el.lastname} - ${el.email}`}</MenuItem>
                        ))
                    }
              </TextField>*/}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}>
                <TextField
                  id="cantSelect"
                  select={true}
                  //defaultValue={1}
                  value={cantSelect}
                  onChange={handleChange}
                  inputProps={{
                    name: "cantidad seleccionada",
                    id: "cantSelect"
                  }}
                  label={(() => {
                    switch (payMethod) {
                      case "Monthly": return "Cantidad de Meses";
                      case "Weekly": return "Cantidad de Semanas";
                      case "Daily": return "Cantidad de Dias";
                    }
                  })()}
                  className={styles.textField}
                  SelectProps={{
                    MenuProps: {
                      //className: styles.menu,
                    },

                  }}
                  //smargin="5px"
                  variant="outlined"
                  borderRadius="50px"
                >
                  {
                    values[payMethod].map((value, index) => {
                      return <MenuItem value={value}>{value}</MenuItem>;
                    })}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  select
                  fullWidth
                  onChange={handleFieldChange}
                  label="Sede"
                  name="headQuarter"
                  value={formState.headQuarter}
                  variant="outlined">
                  {
                    headQuarters.map((el, idx) => (
                      <MenuItem value={el.id}>{`${el.name}`}</MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
              {
                formState.headQuarter || officeDisplay && typeof offices != 'undefined' ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Código de promoción"
                      name="promo_code"
                      onChange={hadleChangePromo}
                      value={promoCode}
                      variant="outlined"
                      helperText={"Si no tienes uno déjalo en blanco"}

                    />
                  </Grid>
                ) : (
                    null
                  )
              }

              {
                officeDisplay && typeof offices != 'undefined' || formState.office ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    style={{ display: officeDisplay }}
                  >
                    <TextField
                      select
                      fullWidth
                      onChange={handleFieldChange}
                      label="Oficinas"
                      name="office"
                      value={formState.office}
                      variant="outlined">
                      {
                        offices.map((el, idx) => (
                          <MenuItem value={el.id}>{`${el.name}`}</MenuItem>
                        ))
                      }
                    </TextField>
                  </Grid>

                ) : (
                    null
                  )
              }
              {
                 formState.headQuarter || officeDisplay && typeof offices != 'undefined' ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Costo Neto de la reserva"
                      name="costo"
                      onChange={handleFieldChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonetizationOnIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={resumenCompra.amount}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                    null
                  )
              }
              {
                resumenCompra.discount != 0 ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Monto con descuento"
                      name="costo"
                      onChange={handleFieldChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonetizationOnIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={resumenCompra.amount_with_discount}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                    null
                  )
              }{
                resumenCompra.discount != 0 ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Descuento"
                      name="costo"
                      onChange={handleFieldChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonetizationOnIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={resumenCompra.discount}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                    null
                  )
              }
              {
                 formState.office || calendarDisplay && typeof dataDisableDays != 'undefined' ? (

                  <Grid
                    item
                    className={classes.containerCalendar}
                  >
                    <DateRangePicker
                      locale={es}
                      showDateDisplay={false}
                      dragSelectionEnabled={false}
                      className={'mainWrapper'}
                      ranges={[dateRange]}
                      rangeColors={["#DE892A"]}
                      disabledDates={dataDisableDays.ranges}
                      scroll={{ enabled: true }}
                      monthHeight={0}
                      direction="vertical"
                      onChange={handleCalendarChange}
                      minDate={new Date()}
                    />
                  </Grid>
                ) : (
                    null
                  )
              }

              <Grid item />
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={handleBookingCheck}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
          <SnackBarWrapper message={errorMessage2.message} />
        </form>
        {
          loadingRequest ? (
            <div className='loottieAnimation'>
              <Lottie
                options={{
                  animationData: animation
                }}
                style={{
                  position: 'fixed',
                  left: '50%',
                  zIndex: 1000,
                  background: 'rgba(0,0,0,0.2)',
                  transform: 'translateX(-50%) translateY(-50%)',
                  top: '50%'
                }}
              />
            </div>
          ) : (null)
        }
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  payMethod: PropTypes.string,
};

CustomerEdit.defaultProps = {
  open: false,
  //payMethod: 'Monthly',
  onClose: () => { }
};

export default CustomerEdit;