import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import config from './../../config'
import axios from 'axios';
import { Page, SearchBar } from 'components';
import { Header, Results, CustomerEdit } from './components';
import { SnackbarContent, Snackbar, Typography } from '@material-ui/core';
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import {setInterceptors} from './../../services/axios'
import useRouter from 'utils/useRouter';
import {getCookie} from './../../services/cookies'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  errorMessage:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconError:{
    marginRight: '0.5em',
  }
}));

const Promos = (props) => {
  const classes = useStyles();
  const router = useRouter();
  setInterceptors(router)
  const [payments, setPayments] = useState();
  const [willPaginate, setWillPaginate] = useState();
  const [openEdit, setOpenEdit] = useState(false);

  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    'message': '',
    'color': ''
  })

  const fetchCustomers = (page, perPage) => {
    axios.get(`${config.server}/getPromosPaginate?page=${page}&per_page=${perPage}`,{
        headers: {
            'Authorization': getCookie('__tw__')
        },
    }).then(response => {
        setPayments(response.data.promos);
        setWillPaginate({page:response.data.page-1, pages: response.data.pages, totalRows: response.data.total_rows})
    }).catch(e => {

    });
  };

  useEffect(() => {
    let mounted = true;

    fetchCustomers(1,10);

    return () => {
      mounted = false;
    };
  }, []);

  const handleFilter = () => {};
  const handleSearch = () => {};

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

  return (
    <Page
      className={classes.root}
      title="Códigos de promocion"
    >
      <Header handleEditOpen={handleEditOpen}/>
      {/*<SearchBar
        onFilter={handleFilter}
        onSearch={handleSearch}
      />*/}
  {payments && willPaginate && (
        <Results
          className={classes.results}
          payments={payments}
          fetchCustomers={fetchCustomers}
          willPaginate={willPaginate}
        />
      )}
      <CustomerEdit
        onClose={handleEditClose}
        open={openEdit}
        fetchCustomers={fetchCustomers}
        setOpenSnack={setOpenSnack}
        setErrorMessage={setErrorMessage}
      />
    <SnackBarWrapper message={errorMessage.message}/>
    </Page>
  );
};

export default Promos;
