import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import config from './../../../../../../config'
import axios from 'axios'
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Typography
} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {getCookie} from './../../../../../../services/cookies'

const useStyles = makeStyles(theme => ({
  root: {},
  mainActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notice: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));
const OtherActions = props => {
  const { history, setErrorMessage, setOpenSnack, headQuarterData, className, ...rest } = props;

  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false)

  const handleDeleteHeadQuarter = async() => {
      try{
          const response = await axios({
              method: 'DELETE',
              headers: {
                  'Authorization': getCookie('__tw__')
              },
              url: `${config.server}/head_quarters/${headQuarterData.id}`,
          })
          if(response.data.state == "SUCCESS"){
              history.push('/sedes')
          }else{
              setErrorMessage({'message':'No puedes eliminar esta sede, aun cuenta con espacios.','color':'#d32f2f'})
              setOpenSnack(true)
              setOpenDialog(false)
          }

      }catch(e){
          console.log(e)
      }
  }

  const DialogWrapper = () => {
      return (<Dialog
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
      >
        <DialogTitle>{"Estas seguro que deseas eliminar esta sede?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Eliminar la sede implica borrar todos sus datos de forma permanente
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteHeadQuarter} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Eliminar"/>
      <Divider />
      <CardContent style={{paddingTop: 0}}>
        <Typography
          className={classes.notice}
          variant="body2"
        >
          Recuerda que para eliminar una sede, debes eliminar primero sus espacios
        </Typography>
        <Button className={classes.deleteButton} onClick={()=>setOpenDialog(true)}>
          <DeleteIcon className={classes.buttonIcon} />
          Eliminar sede
        </Button>
      </CardContent>
      <DialogWrapper/>
    </Card>
  );
};

OtherActions.propTypes = {
  className: PropTypes.string
};

export default OtherActions;
