import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import config from '../../config';
import axios from 'axios';
import { Page, SearchBar } from 'components';
import { Header, Results, CustomerEdit } from './components';
import { SnackbarContent, Snackbar, Typography, Grid } from '@material-ui/core';
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import {setInterceptors} from '../../services/axios'
import useRouter from 'utils/useRouter';
import {getCookie} from '../../services/cookies'
import Cable from 'actioncable';
import ProgressBar from './ProgressBar'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  errorMessage:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconError:{
    marginRight: '0.5em',
  }
}));

  const Publicaciones = (props) => {
  const classes = useStyles();
  const router = useRouter();
  setInterceptors(router)

  const [headQuarters, setHeadQuarters] = useState();
  const [willPaginate, setWillPaginate] = useState();
  const [spaces, setSpaces] = useState();
  const [activatePublish, setActivatePublish] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [socket, setSocket] = useState(false);
  const [searchValue, setSearchValue] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    'message': '',
    'color': ''
  })

  const handleCheckPublish = async() => {
	  try{
		  const response = await axios({
			  method: 'GET',
			  headers: {
				  'Authorization': getCookie('__tw__')
			  },
			  url: `${config.server}/unpublishedData`
      });
		  setHeadQuarters(response.data.data)
	  }catch(e){
      console.log(e)
	  }
  }

  const fetchCustomers = (page, perPage) => {
    axios.get(`${config.server}/getHeadQuartersPaginate?page=${page}&per_page=${perPage}`).then(response => {
        var sedesFalse = [];
        response.data.head_quarters.map((element, index)=>{
          if(element.mounted ===false){
            sedesFalse.push(element)
          }
        })
        setHeadQuarters(sedesFalse);
/*         setWillPaginate({page:response.data.page-1, pages: response.data.pages, totalRows: response.data.total_rows}) */
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted){
      handleCheckPublish()
      createSocket();
      /* fetchCustomers(1,10);
      fetchOffices(1,10); */
    }

    return () => {
      mounted = false;
    };
  }, []);

  const createSocket = ()=> {
    let cable = Cable.createConsumer(`${config.socketServer}/cable`);
      cable.subscriptions.create({
      channel: 'ReportChannel'
    }, {
      connected: (data) => {console.log(data, "socket connected")
      if(window.socket){
        setPublishing(false)
        setErrorMessage({'message':'Los cambios se han publicado.','color':'#43a047'})
        setOpenSnack(true)
      }
    
    },
      received: (data) => {
        console.log('socket update', data)
        /* let chatLogs = this.state.chatLogs;
        chatLogs.push(data)
        this.setState({ chatLogs: chatLogs}) */
        if(data.status === "started"){
          setPublishing(true)
          window.socket = true
          setErrorMessage({'message':'Los cambios se estan publicando, por favor permanece en esta ventana hasta finalizar.','color':'#1976d2'})
          setOpenSnack(true)
        }
        if(data.status === "finished"){
          setPublishing(false)
          setErrorMessage({'message':'Los cambios se han publicado.','color':'#43a047'})
          setOpenSnack(true)
        }
      },
      disconnected: (data) => {
        console.log(data, "socket disconnected")

        if(window.socket){
          setPublishing(false)
          setErrorMessage({'message':'Los cambios se han publicado.','color':'#43a047'})
          setOpenSnack(true)
        }
      }
    });
  }

  const handleSearch = async() => {
	  try{
		  const response = await axios({
        params: {name: searchValue},
			  method: 'GET',
			  headers: {
				  'Authorization': getCookie('__tw__')
			  },
			  url: `${config.server}/searchHeadQuarters`
      });
      setHeadQuarters(response.data);
	  }catch(e){
      console.log(e)
	  }
  }

  const handleChange = event => {
    event.persist();

    setSearchValue(event.target.value);
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
                    <IoIosWarning size={20} className={classes.iconError}/>
									)
								)
							}
						    <Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

  return (
    <Page
      className={classes.root}
      title="Sedes"
    >
      <Header
		  handleCheckPublish={handleCheckPublish}
		  handleEditOpen={handleEditOpen}
		  fetchCustomers={fetchCustomers}
      setOpenSnack={setOpenSnack}
		  publishing={publishing}
          setErrorMessage={setErrorMessage}
	  />
      {/* <SearchBar
        onSearch={handleSearch}
        fetchCustomers = {fetchCustomers}
        handleCheckPublish = {handleCheckPublish}
        handleChange = {handleChange}
      /> */}
      {publishing && 
      <>
      
      <ProgressBar style = {{marginTop: "2rem"}}/>
      <Grid item>
      <Typography
        component="h2"
        gutterBottom
        variant="overline"
      >
        Publicando cambios, por favor no cierres esta ventana
      </Typography>
    </Grid>
      </>}
      {headQuarters  && (
        <Results
          className={classes.results}
          headQuarters={headQuarters}
          fetchCustomers={fetchCustomers}
          willPaginate={willPaginate}
        />
      )}
      <CustomerEdit
        onClose={handleEditClose}
        open={openEdit}
	    	handleCheckPublish={handleCheckPublish}
        fetchCustomers={fetchCustomers}
        setOpenSnack={setOpenSnack}
        setErrorMessage={setErrorMessage}
      />
    <SnackBarWrapper message={errorMessage.message}/>
    </Page>
  );
};

export default Publicaciones;
