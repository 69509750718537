import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import config from './../../config'
import axios from 'axios';
import { Page, SearchBar } from 'components';
import { Header, Results, CustomerEdit } from './components';
import { SnackbarContent, Snackbar, Typography } from '@material-ui/core';
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import {setInterceptors} from './../../services/axios'
import useRouter from 'utils/useRouter';
import {getCookie} from './../../services/cookies'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  errorMessage:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconError:{
    marginRight: '0.5em',
  }
}));

const HeadQuarters = (props) => {
  const classes = useStyles();
  const router = useRouter();
  setInterceptors(router)

  const [headQuarters, setHeadQuarters] = useState();
  const [willPaginate, setWillPaginate] = useState();
  const [activatePublish, setActivatePublish] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [searchValue, setSearchValue] = useState(false);

  const [openSnack, setOpenSnack] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    'message': '',
    'color': ''
  })

  const handleCheckPublish = async() => {
	  try{
		  const response = await axios({
			  method: 'GET',
			  headers: {
				  'Authorization': getCookie('__tw__')
			  },
			  url: `${config.server}/checkPublished`
		  });
		  setActivatePublish(response.data)
	  }catch(e){

	  }
  }

  const fetchCustomers = (page, perPage) => {
    axios.get(`${config.server}/getHeadQuartersPaginate?page=${page}&per_page=${perPage}`).then(response => {
        console.log(response.data)
        setHeadQuarters(response.data.head_quarters);
        setWillPaginate({page:response.data.page-1, pages: response.data.pages, totalRows: response.data.total_rows})
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted){
      fetchCustomers(1,10);
      handleCheckPublish()
    }

    return () => {
      mounted = false;
    };
  }, []);

  const handleSearch = async() => {
	  try{
		  const response = await axios({
        params: {name: searchValue},
			  method: 'GET',
			  headers: {
				  'Authorization': getCookie('__tw__')
			  },
			  url: `${config.server}/searchHeadQuarters`
      });
      console.log(response)
      setHeadQuarters(response.data);
	  }catch(e){
      console.log(e)
	  }
  }

  const handleChange = event => {
    event.persist();

    setSearchValue(event.target.value);
  };

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={classes.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={classes.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={classes.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <Typography style={{color:'#fff'}}>{message}</Typography>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

  return (
    <Page
      className={classes.root}
      title="Sedes"
    >
      <Header
		  handleCheckPublish={handleCheckPublish}
		  handleEditOpen={handleEditOpen}
		  fetchCustomers={fetchCustomers}
      setOpenSnack={setOpenSnack}
		  activatePublish={activatePublish}
          setErrorMessage={setErrorMessage}
	  />
      {<SearchBar
/*         onFilter={handleFilter} */
        onSearch={handleSearch}
        fetchCustomers = {fetchCustomers}
        handleCheckPublish = {handleCheckPublish}
        handleChange = {handleChange}
      />}
  {headQuarters && willPaginate && (
        <Results
          className={classes.results}
          headQuarters={headQuarters}
          fetchCustomers={fetchCustomers}
          willPaginate={willPaginate}
        />
      )}
      <CustomerEdit
        onClose={handleEditClose}
        open={openEdit}
	    	handleCheckPublish={handleCheckPublish}
        fetchCustomers={fetchCustomers}
        setOpenSnack={setOpenSnack}
        setErrorMessage={setErrorMessage}
      />
    <SnackBarWrapper message={errorMessage.message}/>
    </Page>
  );
};

export default HeadQuarters;
