import React, { useState } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../../../config'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Switch,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import {getCookie} from '../../../../../../services/cookies'
import { Label } from 'components';
import { CustomerEdit } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  encrypt:{
    backgroundColor: '#86a6df',
    color: '#fff',
    padding: '0.5em',
    width: 'max-content',
    fontWeight: '700',
    borderRadius: '0.5em'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CustomerInfo = props => {
  const { setErrorMessage, setOpenSnack, users, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);
  const [toggleState, setToggleState] = useState(users.email_confirmed)

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleChangeConfirm = async() => {
      try{
          const response = await axios({
              method: 'PUT',
              headers: {
                  'Authorization': getCookie('__tw__')
              },
              url: `${config.server}/users/${users.id}`,
              data: {email_confirmed: !toggleState}
          });
          if(response.data.state == "SUCCESS"){
              setToggleState(!toggleState);
          }
      }catch(e){
          console.log(e)
      }
  }

  const handleResendConfirmation = async() => {
      try{
          const response = await axios({
              method: 'POST',
              headers: {
                  'Authorization': getCookie('__tw__')
              },
              url: `${config.server}/resend_confirmation/`,
              data: {id: users.id}
          });
          if(response.data.state == "SUCCESS"){
              setErrorMessage({'message':'Email enviado!','color':'#43a047'})
              setOpenSnack(true)
          }
      }catch(e){
          console.log(e)
      }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Usuario info" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>
                {users.name}
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Apellido</TableCell>
                <TableCell>
                    {users.lastname}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                    {users.email}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Telefono</TableCell>
                <TableCell>
                    {users.phone}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Contraseña</TableCell>
                <TableCell><div className={classes.encrypt}>Encrypted</div></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Codigo de promocion</TableCell>
                <TableCell>
                    {users.promo_code}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Tipo de usuario</TableCell>
                <TableCell>
                    {users.user_type.charAt(0).toUpperCase()+users.user_type.slice(1)}
                </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email confirmado</TableCell>
              <TableCell>
                  <Switch
                    checked={toggleState}
                    color="secondary"
                    edge="start"
                    name="email_confirmed"
                    onChange={handleChangeConfirm}
                  />
              </TableCell>

            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Editar
        </Button>
        <Button onClick={handleResendConfirmation} disabled={toggleState}>
          <ReceiptIcon className={classes.buttonIcon} />
          Reenviar confirmacion
        </Button>
      </CardActions>
      <CustomerEdit
        setErrorMessage={setErrorMessage}
        setOpenSnack={setOpenSnack}
        users={users}
        onClose={handleEditClose}
        open={openEdit}
        fetchCustomers={fetchCustomers}
      />
    </Card>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  users: PropTypes.object.isRequired
};

export default CustomerInfo;
