import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  colors
} from '@material-ui/core';
import config from './../../config'
import {getCookie} from './../../services/cookies'
import axios from 'axios'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreIcon from '@material-ui/icons/MoreVert';

import bytesToSize from 'utils/bytesToSize';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const FilesDropzone = props => {
  const { setErrorMessage, setOpenSnack, fetchImages, spaceData, className, ...rest } = props;
  const classes = useStyles();

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(async acceptedFiles => {
    setFiles(files => [...files].concat(acceptedFiles));

	acceptedFiles.map(async(el,idx)=>{
		let options = {
		  method: 'POST',
		  headers: {
			  'Authorization': getCookie('__tw__')
		  },
		};

		options.body = new FormData();
		options.body.append('image', el)
		options.body.append('office_id', spaceData.id)
		try{
			const response = await fetch(`${config.server}/office_images`,options);
			fetchImages()
			if(response.statusText == "Created"){
				setErrorMessage({'message':'Imagenes agregadas.','color':'#43a047'})
				setOpenSnack(true)
			}
		}catch(e){
			console.log(e)
		}
	})

  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography
            gutterBottom
            variant="h3"
          >
            Selecciona imagenes
          </Typography>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            Arrastra imagenes aqui o da <Link underline="always">click</Link>{' '}
            a traves de tu maquina
          </Typography>
        </div>
      </div>
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;
