import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardActions,
  colors,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import getInitials from 'utils/getInitials';
import { Label, ReviewStars, GenericMoreButton, TableEditBar } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const Results = props => {
  const { fetchCustomers, willPaginate, className, actualHeadQuarterData, spaces, ...rest } = props;

  const classes = useStyles();

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [page, setPage] = useState(willPaginate.page);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAll = event => {
    const selectedCustomers = event.target.checked
      ? spaces.map(space => space.id)
      : [];

    setSelectedCustomers(selectedCustomers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomers.indexOf(id);
    let newSelectedCustomers = [];

    if (selectedIndex === -1) {
      newSelectedCustomers = newSelectedCustomers.concat(selectedCustomers, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(1)
      );
    } else if (selectedIndex === selectedCustomers.length - 1) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomers = newSelectedCustomers.concat(
        selectedCustomers.slice(0, selectedIndex),
        selectedCustomers.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomers(newSelectedCustomers);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    fetchCustomers(page+1, rowsPerPage)
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    fetchCustomers(page+1, event.target.value)
  };

  const statusColors = {
  	true: colors.green[600],
  	false: colors.red[600]
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {spaces.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(spaces.length / rowsPerPage)}
      </Typography>
      {
        spaces.length > 0 ? (
            <Card>
              <CardHeader
                action={<GenericMoreButton />}
                title={`Todos los espacios de ${actualHeadQuarterData.name}`}
              />
              <Divider />
              <CardContent className={classes.content}>
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedCustomers.length === spaces.length}
                              color="primary"
                              indeterminate={
                                selectedCustomers.length > 0 &&
                                selectedCustomers.length < spaces.length
                              }
                              onChange={handleSelectAll}
                            />
                          </TableCell> */}
                          <TableCell>Nombre</TableCell>
                          <TableCell>Tamaño</TableCell>
                          <TableCell>Capacidad</TableCell>
                          <TableCell>Tipo de espacio</TableCell>
      					<TableCell  >Publicado</TableCell>
                          <TableCell align="right">Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {spaces.slice(0, rowsPerPage).map(space => (
                          <TableRow
                            hover
                            key={space.id}
                            selected={selectedCustomers.indexOf(space.id) !== -1}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={
                                  selectedCustomers.indexOf(space.id) !== -1
                                }
                                color="primary"
                                onChange={event =>
                                  handleSelectOne(event, space.id)
                                }
                                value={selectedCustomers.indexOf(space.id) !== -1}
                              />
                            </TableCell> */}
                            <TableCell>{space.name}</TableCell>
                            <TableCell>{space.size} m²</TableCell>
                            <TableCell>{space.capacity} personas</TableCell>
                            <TableCell>{space.office_type_name}</TableCell>
      					            <TableCell>
                                <Label
                                  color={statusColors[space.mounted]}
                                  variant="outlined"
                                >
                                  {space.mounted.toString()}
                                </Label>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                color="primary"
                                component={RouterLink}
                                size="small"
                                to={`/espacio/${space.id}`}
                                variant="outlined"
                              >
                                Ver
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
              </CardContent>
              <CardActions className={classes.actions}>
                <TablePagination
                  component="div"
                  count={willPaginate.totalRows}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              </CardActions>
            </Card>
        ):(
            <Typography> No hay datos para mostrar </Typography>
        )
      }
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  spaces: PropTypes.array.isRequired
};

Results.defaultProps = {
  spaces: []
};

export default Results;
