import * as actionTypes from 'actions';

const initialState = {
  estado: "socket desconectado",
  error: null, 
  sedesEnProgreso: [],
};


const publishReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PUBLISH_CONNECT: {
      return {...state, estado: "conectando socket"}
    }
    case actionTypes.PUBLISH_CONNECTED:{
      return {...state, estado: "socket conectado"} ;
      }
    case actionTypes.PUBLISH_DISCONNECTED:{
      return {...state, estado: "socket desconectado"} ;
      }
    case actionTypes.PUBLISH_RECEIVED: {
      return {...state, estado: "publicando", sedesEnProgreso: action.sedes};
    }
    default: {
      return state;
    }
  }
};
/* const publishReducer = (state=initialState,action)=>{
	switch (action.type) {
		case actionTypes.publish_DISCONNECTED:{
      return {...state, estado: "conectando socket"} 
    }
    case actionTypes.publish_DISCONNECTED:{
      return {...state, estado: "socket conectado"} ;
      }
    case actionTypes.publish_DISCONNECTED:{
      return {...state, estado: "socket desconectado"} ;
      }
      case actionTypes.publish_RECEIVED:{
        return {...state, estado: "publicando", sedesEnProgreso: sedes} ;
        }
  }
} */

export default publishReducer;
