export const PUBLISH_CONNECT = 'PUBLISH_CONNECT';
export const PUBLISH_CONNECTED = 'PUBLISH_CONNECTED';
export const PUBLISH_RECEIVED = 'PUBLISH_RECEIVED';
export const PUBLISH_DISCONNECTED = 'PUBLISH_DISCONNECTED';



  export const socketConnect = () => dispatch =>
  dispatch({
    type: PUBLISH_CONNECT
  });
  export const socketConnected = () => dispatch =>
  dispatch({
    type: PUBLISH_CONNECTED
  });
  export const socketReceived = () => dispatch =>
  dispatch({
    type: PUBLISH_RECEIVED
  });
  export const sockectDisconnected = () => dispatch =>
  dispatch({
    type: PUBLISH_DISCONNECTED
  });

