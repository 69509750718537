import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { Router , BrowserRouter, Route} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import routes from '../../routes';

import { Topbar } from './components';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  }
}));

const Auth = props => {
  const classes = useStyles();

  return (
    <Fragment>
      <Topbar />
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
			{
			  routes[0].routes.map((route,idx)=>{
				  return(
					  <Route
						key={route.path}
						path={route.path}
						exact={true}
						render={(props) => {
							return React.cloneElement(route.component, {match:props.match, history: props.history})
						}}
					  />
				  )
			  })
			}
        </Suspense>
      </main>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
