import React, { useState } from 'react';
import clsx from 'clsx';
import config from './../../../../../../config'
import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  MenuItem,
  Typography,
  TextField,
  Switch,
  Button,
  colors
} from '@material-ui/core';
import Lottie from 'lottie-react-web'
import animation from './../../../../../../animations/loading-cowork2.json'
import {getCookie} from './../../../../../../services/cookies'


const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const CustomerEdit = props => {
  const { handleCheckPublish, actualHeadQuarterData, officeTypes, setErrorMessage, setOpenSnack, open, onClose, headQuarterData, fetchCustomers, className, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
      name: '',
      description: '',
      capacity: '',
      daily: '',
      weekly: '',
      monthly: '',
      office_type_id: ''
  });

  const [loadingRequest, setLoadingRequest] = useState(false)

  if (!open) {
    return null;
  }

  const onSave = async() => {
      if ((/^\s*$/.test(formState.name)) ||
      (/^\s*$/.test(formState.description)) ||
      (/^\s*$/.test(formState.capacity)) ||
      (/^\s*$/.test(formState.size)) ||
      (/^\s*$/.test(formState.daily)) ||
      (/^\s*$/.test(formState.weekly)) ||
      (/^\s*$/.test(formState.monthly)) ||
      (/^\s*$/.test(formState.office_type_id))){
          setErrorMessage({'message':'No puedes dejar valores en blanco.','color':'#d32f2f'})
          setOpenSnack(true)
      }else{
          setLoadingRequest(true)
          try{
              const response = await axios({
                  method: 'POST',
                  url: `${config.server}/offices`,
                  headers: {
                      'Authorization': getCookie('__tw__')
                  },
                  data: {
                      name: formState.name,
                      description: formState.description,
                      capacity: formState.capacity,
                      daily: formState.daily,
                      weekly: formState.weekly,
                      monthly: formState.monthly,
                      size: formState.size,
                      office_type_id: formState.office_type_id,
                      head_quarter_id: actualHeadQuarterData.id
                  }
              });
              if (response.data.state == "SUCCESS"){
				  handleCheckPublish()
				  onClose()
				  fetchCustomers(1,10)
				  setErrorMessage({'message':'Espacio creado!','color':'#43a047'})
				  setOpenSnack(true)
				  setFormState({
					  name: '',
					  description: '',
					  capacity: '',
					  office_type_id: ''
				  })
                  /*[0,1,2].map(async(el,idx)=> {
                      try{
                          const responseTypeHeadQuarter = await axios({
                              method: 'POST',
                              url: `${config.server}/auto_image`,
                              headers: {
                                  'Authorization': getCookie('__tw__')
                              },
                              data: {
                                  office_id: response.data.data.id
                              }
                          });
                          if (0 == idx){
							  handleCheckPublish()
                              onClose()
                              fetchCustomers(1,10)
                              setErrorMessage({'message':'Espacio creado!','color':'#43a047'})
                              setOpenSnack(true)
                              setFormState({
                                  name: '',
                                  description: '',
                                  capacity: '',
                                  office_type_id: ''
                              })
                          }
                      }catch(e){
                          console.log(e)
                      }
                  })*/
              }
          }catch(e){

          }
          setLoadingRequest(false)
      }
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Agregar espacio
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={handleFieldChange}
                  value={formState.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
                    label="Descripcion"
                    name="description"
                    onChange={handleFieldChange}
                    value={formState.description}
                    variant="outlined"
                  />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
                    label="Capacidad"
                    name="capacity"
                    type="number"
                    onChange={handleFieldChange}
                    value={formState.capacity}
                    variant="outlined"
                  />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
                    label="Tamaño en m²"
                    name="size"
                    type="number"
                    onChange={handleFieldChange}
                    value={formState.size}
                    variant="outlined"
                  />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
                    label="Precio diario"
                    name="daily"
                    type="number"
                    onChange={handleFieldChange}
                    value={formState.daily}
                    variant="outlined"
                  />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
                    label="Precio semanal"
                    name="weekly"
                    type="number"
                    onChange={handleFieldChange}
                    value={formState.weekly}
                    variant="outlined"
                  />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
                    label="Precio mensual"
                    name="monthly"
                    type="number"
                    onChange={handleFieldChange}
                    value={formState.monthly}
                    variant="outlined"
                  />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                    fullWidth
                    select
                    label="Tipo de espacio"
                    name="office_type_id"
                    onChange={handleFieldChange}
                    value={formState.office_type_id}
                    variant="outlined"
                  >
                      {
                         typeof officeTypes !== 'undefined' ? (
                             officeTypes.map((el,idx)=> (
                                 <MenuItem value={el.office_type_id}>{el.name}</MenuItem>
                             ))
                         ) : (
                             null
                         )
                      }
                  </TextField>
              </Grid>
              <Grid item/>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
        {
            loadingRequest ? (
                <div className='loottieAnimation'>
                    <Lottie
                        options={{
                          animationData: animation
                        }}
                        style={{
                            position: 'fixed',
                            left: '50%',
                            zIndex: 1000,
                            background: 'rgba(0,0,0,0.2)',
                            transform: 'translateX(-50%) translateY(-50%)',
                            top: '50%'
                        }}
                      />
                </div>
            ): (null)
        }
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

CustomerEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerEdit;
