import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyAM_Vnd5sRbUqGLTLhxvwJ2wAffX5K7kVo");
Geocode.enableDebug();

const mapStyles = {
  width: '100%',
  height: '100%',
  borderRadius: '0.8em'
};

const MapView = ({google, latitude, longitude}) => {
    const dispatch = useDispatch();

    const [coordinates, setCoordinates] = useState({
        'lat': latitude,
        'lng': longitude
    })

    const mapClicked = (mapProps, map, clickEvent) => {
        Geocode.fromLatLng(clickEvent.latLng.lat(), clickEvent.latLng.lng()).then(
          response => {
              const coordinatesMap = {
                  'lat': clickEvent.latLng.lat(),
                  'lng': clickEvent.latLng.lng(),
                  'add': response.results[0].formatted_address
              }
              setCoordinates(coordinatesMap)
              dispatch({ type: 'map/MODIFY_MAP_MARKER', data: coordinatesMap })
          },
          error => {
            console.error(error);
          }
        );
    }

	return(
		<Map
			style={mapStyles}
			google={google}
            streetViewControl={false}
            mapTypeControl={false}
            onClick={mapClicked }
            scaleControl={false}
            zoomControl={false}
            rotateControl={false}
            fullscreenControl={false}
			zoom={15}
            center={coordinates}
			initialCenter={coordinates}
		>
			<Marker position={coordinates} />
		</Map>
	)
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAM_Vnd5sRbUqGLTLhxvwJ2wAffX5K7kVo'),
})(MapView)
