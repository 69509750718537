import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Tooltip,
  Typography,
  colors
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import EditIcon from '@material-ui/icons/Edit';
import config from './../../../../../../config'
import {getCookie} from './../../../../../../services/cookies'
import bytesToSize from 'utils/bytesToSize';

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    height: 240,
	backgroundSize: 'contain'
  },
  placeholder: {
    height: 240,
    backgroundColor: colors.blueGrey[50],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  insertDriveFileIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    fontSize: theme.spacing(6)
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  actions: {
    justifyContent: 'center',
	position: 'relative'
  },
  getAppIcon: {
    marignRight: theme.spacing(1)
  },
  menu: {
    width: 250,
    maxWidth: '100%'
  },
  tooltipDots:{
	  position: 'absolute',
	  right: '1em'
  }
}));

const FileCard = props => {
  const { setErrorMessage, setOpenSnack, fetchImages, file, className, ...rest } = props;
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false)

  const handleRemoveImage = async() => {
	  try{
		  const response = await fetch(`${config.server}/office_images/${file.id}`,{
			  method: 'DELETE',
              headers: {
                  'Authorization': getCookie('__tw__')
              }
		  });
		  fetchImages()
		  setErrorMessage({'message':'Imagen eliminada.','color':'#43a047'})
		  setOpenSnack(true)
	  }catch(e){
		  console.log(e)
	  }
  }

  const handleDownloadImage = () => {
	  window.open(`${config.assets}/${file.image_uid}`, '_blank');
  }

  const DialogWrapper = () => {
      return (<Dialog
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
      >
        <DialogTitle>{"Estas seguro que deseas eliminar esta imagen?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Eliminar la imagen implica que no podra volver a recuperarse
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleRemoveImage} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
        <CardMedia
          className={classes.media}
          image={`${config.assets}/${file.image_uid}`}
        />
     {/* <CardContent className={classes.content}>
        <div>
          <Typography variant="subtitle2">{bytesToSize(file.size)}</Typography>
        </div>

      </CardContent>*/}
      <Divider />
      <CardActions className={classes.actions}>
        <Button onClick={handleDownloadImage}>
          <GetAppIcon className={classes.getAppIcon} />
          Download
        </Button>
		<Button onClick={()=>setOpenDialog(true)}>
          <DeleteIcon className={classes.getAppIcon} />
          Eliminar
        </Button>
      </CardActions>
	  <DialogWrapper/>
    </Card>
  );
};

FileCard.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object.isRequired
};

export default FileCard;
