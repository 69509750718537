import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import config from '../../../../config'
import {getCookie} from '../../../../services/cookies'
import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Select from 'react-select'
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Lottie from 'lottie-react-web'
import animation from './../../../../animations/loading-cowork2.json'
import {
  Modal,
  Card,
  MenuItem,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Switch,
  Button,
  colors,
  InputAdornment
} from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const customSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '.9rem',
    color: '#263238'
  }),
  menuPortal: styles => ({ ...styles, zIndex: 10000000 })
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3),
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  containerCalendar:{
      marginTop: theme.spacing(1)
  }
}));

const CustomerEdit = props => {
  const { bookingsID, bookings, office_id, setErrorMessage, setOpenSnack, open, onClose, fetchCustomers, className, ...rest } = props;

  console.log('bookings',bookings)

  const classes = useStyles();
  const [users, setUsers] = useState();
  const [headQuarters, setHeadQuarters] = useState();
  const [offices, setOffices] = useState();
  const [usersOptions,setUsersOptions] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [officeDisplay, setOfficeDisplay] = useState(false)
  /*   const [errorMessage, setErrorMessage] = useState({
    'message': '',
    'color': ''
  }); */

  const [formState, setFormState] = useState({
      amount: '',
      booking: ''
  });
  const [loadingRequest, setLoadingRequest] = useState(false)

  const handleChangeDate = (value) => {
	  setSelectedDate(value)
  }

  const onSave = async() => {
      setLoadingRequest(true)
	  if ((/^\s*$/.test(formState.amount))){
      setErrorMessage({'message':'No puedes dejar valores en blanco.','color':'#d32f2f'})
        setOpenSnack(true)
	  }else{
		  try{
          const response = await axios({
            method: 'POST',
            headers: {
                'Authorization': getCookie('__tw__')
            },
            url: `${config.server}/createPayment`,
            data: {
              amount: formState.amount,
              booking_id: bookingsID,
              office_id: office_id,
              date: selectedDate
          }
        }); 
			  if(response.data.message == "ALREADY_PAY"){
				  setErrorMessage({'message':'Ya existe un pago para esta reserva!','color':'#d32f2f'})
                  setOpenSnack(true)
			  }else if(response.data.message == "DOESNT_EXIST"){
				  setErrorMessage({'message':'Este ID de reserva no existe, por favor verifica el ID en el gestor de reservas!','color':'#d32f2f'})
                  setOpenSnack(true)
			  }else if(response.data.message == "SUCCESS"){
				  setErrorMessage({'message':'Pago creado!','color':'#43a047'})
				  setOpenSnack(true)
				  setFormState({
					  amount: '',
					  booking: ''
                  })
				  setSelectedDate(new Date())
				  onClose()
				  fetchCustomers(1,10)
        }
        else{
				  setErrorMessage({'message':'Error al crear el pago!','color':'#d32f2f'})
				  setOpenSnack(true)
			  }
		  }catch(e){
		  }
	  }
      setLoadingRequest(false)
  }

  const handleChangeUser = (data) => {
      setFormState(formState => ({
        ...formState,
        user: data
      }));
  }

  const handleFieldChange = event => {
    if(event.target.name == "headQuarter"){
        setOfficeDisplay(true)
        getAllOffice(event.target.value)
        //setFormState({...formState, user: event.target.value})
    }else if(event.target.name == "office"){
    }
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const getAllUsers = () => {
      axios.get(`${config.server}/users`,{
          headers: {
              'Authorization': getCookie('__tw__')
          },
      }).then(response => {
          setUsers(response.data);
          response.data.map((el,idx)=>{
            let temp = usersOptions
            temp.push({
              value: el.id,
              label: `${el.name} ${el.lastname} - ${el.email}`
            })
            setUsersOptions(temp)
          })
      }).catch(e => {

      });
  }
  console.log("entro aqui CustomerEdit Payments")
  const getAllHeadQuarters = () => {
      try{
          axios.get(`${config.server}/head_quarters`).then(response => {
              setHeadQuarters(response.data);
          });
      }catch(e){
      }
  }

  const getAllOffice = (headQuarterId) => {
      try{
          axios.get(`${config.server}/getOfficeByHeadQuarter?headQuarterId=${headQuarterId}`).then(response => {
              setOffices(response.data);
          });
      }catch(e){
      }
  }

/*   useEffect(()=> {
      getAllUsers()
      getAllHeadQuarters()
  },[]) */

  if (!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      id="portal-target"
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Agregar pago
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
			  <Grid
            item
            md={6}
            xs={12}
          >
          <TextField
            fullWidth
            type="number"
            onChange={handleFieldChange}
            label="Cantidad"
            name="amount"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MonetizationOnIcon />
                </InputAdornment>
              ),
            }}
            value={formState.amount}
            variant="outlined"/>
        </Grid>
			  <Grid
				item
				md={6}
				xs={12}
			  >
			  <Typography>Fecha de pago</Typography>
			   <DatePicker style={{width:'100%', height:'100%'}} value={selectedDate} onChange={handleChangeDate} />
			  </Grid>
              <Grid item/>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Cerrar
            </Button>
            <Button
              className={classes.saveButton}
              onClick={onSave}
              variant="contained"
            >
              Guardar
            </Button>
          </CardActions>
        </form>
        {
            loadingRequest ? (
                <div className='loottieAnimation'>
                    <Lottie
                        options={{
                          animationData: animation
                        }}
                        style={{
                            position: 'fixed',
                            left: '50%',
                            zIndex: 1000,
                            background: 'rgba(0,0,0,0.2)',
                            transform: 'translateX(-50%) translateY(-50%)',
                            top: '50%'
                        }}
                      />
                </div>
            ): (null)
        }
      </Card>
    </Modal>
  );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

CustomerEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerEdit;
