/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Typography } from '@material-ui/core';
import config from './../../../../config'
import axios from 'axios'
import useRouter from 'utils/useRouter';
import { login } from 'actions';
import animation from './../../../../animations/loading-cowork2.json'
import Lottie from 'lottie-react-web'

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  errorText: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      color: '#d32f2f',
      fontWeight: 600,
  }
}));

const LoginForm = props => {
  const { className, ...rest } = props;
  const [loadingRequest, setLoadingRequest] = useState(false)

  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
    },
    touched: {},
    errors: {}
  });

  const [credentialsWrong, setCredentialsWrong] = useState(false)

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
	setLoadingRequest(true)
    const response = await axios({
        method: 'POST',
        url: `${config.server}/getAdminLogin`,
        data: {
            email: formState.values.email,
            password: formState.values.password
        }
    });
	setLoadingRequest(false)
    if (response.data.status == 200){
        console.log(response)
        localStorage.setItem('name',`${response.data.user_data.name} ${response.data.user_data.lastname}`)
        document.cookie = `__tw__=${response.data.token}; expires= ${new Date(response.data.exp)}; path=/`
        router.history.push('/sedes');
    }else{
        setCredentialsWrong(true)
    }
    // dispatch(login());
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <TextField
          error={hasError('email')}
          fullWidth
          helperText={hasError('email') ? formState.errors.email[0] : null}
          label="Email address"
          name="email"
          onChange={handleChange}
          value={formState.values.email || ''}
          variant="outlined"
        />
        <TextField
          error={hasError('password')}
          fullWidth
          helperText={
            hasError('password') ? formState.errors.password[0] : null
          }
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          value={formState.values.password || ''}
          variant="outlined"
        />
      </div>
      {
          credentialsWrong != false ? (
              <Typography className={classes.errorText}>Error, usuario o contraseña incorrectos</Typography>
          ):(null)
      }
      <Button
        className={classes.submitButton}
        color="primary"
        disabled={!formState.isValid}
        size="large"
        type="submit"
        variant="contained"
      >
        Iniciar Sesion
      </Button>
	  {
		  loadingRequest ? (
			  <div className='loottieAnimation'>
				  <Lottie
					  options={{
						animationData: animation
					  }}
					  style={{
						  position: 'fixed',
						  left: '50%',
						  zIndex: 1000,
						  background: 'rgba(0,0,0,0.2)',
						  transform: 'translateX(-50%) translateY(-50%)',
						  top: '50%'
					  }}
					/>
			  </div>
		  ): (null)
	  }
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string
};

export default LoginForm;
